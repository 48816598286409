// MoveToPipeline.tsx
import { gray } from "@ant-design/colors";
import { useShow, useTranslate } from "@refinedev/core";
import { Button, Form, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { usePipelineSelect } from "../../../hooks/usePipelineSelect";
import { useStageSelect } from "../../../hooks/useStageSelect";
import { Text } from "../../text";

export const MoveToPipelineDisplay: React.FC<{ item: any }> = ({ item }) => {
  const translate = useTranslate();

  const { query, setShowId } = useShow({
    resource: "pipeline_stages",
    meta: {
      select: `name, pipelines!pipeline_stages_pipeline_fkey(name)`,
    },
  });
  useEffect(() => {
    setShowId(item.config.move_to_stage);
  }, [item.config.move_to_stage, setShowId]);
  const { data } = query;
  return (
    <p>
      {item.config?.move_to_pipeline ? (
        <>
          <Text size={"xs"} style={{ color: gray[4] }}>
            {translate("pipeline_automations.action_settings_move_to_pipeline")}
          </Text>
          <br /> <Text strong>{data?.data.pipelines.name}</Text>
        </>
      ) : null}
      {item.config?.move_to_stage ? (
        <>
          <br />
          <Text size={"xs"} style={{ color: gray[4] }}>
            {translate("pipeline_automations.stage")}
          </Text>
          <br /> <Text strong>{data?.data.name}</Text>
        </>
      ) : null}
    </p>
  );
};

export const MoveToPipelineForm: React.FC<{
  item: any;
  onSave: (config: any) => void;
  onCancel: () => void;
}> = ({ item, onSave, onCancel }) => {
  const [form] = Form.useForm();
  const [selectedPipeline, setSelectedPipeline] = useState<number>();
  const { query: pipelineResult, selectProps: pipelineSelectProps } =
    usePipelineSelect();
  const {
    query: automaticStageResult,
    selectProps: automaticStageSelectProps,
  } = useStageSelect(selectedPipeline);

  useEffect(() => {
    if (item.config?.move_to_pipeline) {
      setSelectedPipeline(item.config.move_to_pipeline);
    }
  }, [item.config?.move_to_pipeline]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSave(values);
    } catch (error) {
      const values = form.getFieldsValue();
      onSave(values);
    }
  };
  const translate = useTranslate();

  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        initialValues={item.config}
      >
        <Form.Item
          label={translate("pipeline_automations.move_to_pipeline")}
          name="move_to_pipeline"
          required
          rules={[{ required: true }]}
        >
          <Select
            {...pipelineSelectProps}
            onChange={(value) => {
              setSelectedPipeline(Number(value));
              form.setFieldValue("move_to_pipeline", value);
              form.setFieldValue("move_to_stage", null);
            }}
            options={
              pipelineResult.data?.data?.map((pipeline) => ({
                value: pipeline.id,
                label: pipeline.name ?? "",
              })) ?? []
            }
          />
        </Form.Item>
        <Form.Item
          label={translate("pipeline_automations.stage")}
          name="move_to_stage"
          required
          rules={[{ required: true }]}
        >
          <Select
            {...automaticStageSelectProps}
            options={
              automaticStageResult.data?.data?.map((stage) => ({
                value: stage.id,
                label: stage.name ?? "",
              })) ?? []
            }
          />
        </Form.Item>
      </Form>
      <div>
        <Space>
          <Button onClick={onCancel}>{translate("buttons.cancel")}</Button>
          <Button type="primary" onClick={handleSubmit}>
            {translate("buttons.save")}
          </Button>
        </Space>
      </div>
    </div>
  );
};
