import { useForm } from "@refinedev/antd";
import {
  BaseKey,
  CanAccess,
  useNavigation,
  useOne,
  useTranslate,
} from "@refinedev/core";
import { Form, Input, Modal, Spin } from "antd";
import React, { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../../contexts/ContextProvider";

const SubscriptionProductsCreate: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { id: prodId } = useParams();
  const { list, show } = useNavigation();
  const { formProps, saveButtonProps, onFinish } = useForm({
    resource: "subscription_products",
    action: "create",
    invalidates: ["all"],

    redirect: false,
    id: 0,
  });
  const { data, isLoading } = useOne({
    resource: "product_items",
    id: prodId as BaseKey,
    queryOptions: {
      enabled: !!prodId,
    },
  });
  console.log("data", data?.data);
  if (isLoading) {
    return <Spin />;
  }

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>
        <Modal
          open
          maskClosable={false}
          title={translate("subscription_products.create.title")}
          onCancel={() => {
            show("product_items", prodId as BaseKey);
          }}
          onClose={() => {
            show("product_items", prodId as BaseKey);
          }}
          okText={translate("buttons.save")}
          okButtonProps={{
            ...saveButtonProps,
          }}
          destroyOnClose={true}
          width={760}
        >
          <Form
            {...formProps}
            layout="vertical"
            onFinish={(values) => {
              onFinish({
                ...values,
                jurisdiction: "DE",
                product_item: prodId,
                account: currentTeam?.account_id,
                phases: [
                  {
                    key: 1,
                    billing_interval: {
                      interval: "month",
                      interval_count: 3,
                    },
                    start: "start",
                    end: { "interval": "month", "interval_count": 12 },
                    products: [
                      {
                        id: "product-1",
                        name: data?.data?.title,
                        type: data?.data?.type,
                        unit: "piece",
                        variant: null,
                        discount: 0,
                        quantity: 1,
                        tax_rate: 19,
                        description: data?.data?.description,
                        reference_id: data?.data?.id,
                        price_per_item: null,
                      },
                    ],
                  },
                ],
              }).finally(() => {
                show("product_items", prodId as BaseKey);
              });
            }}
          >
            <Form.Item
              label={translate("subscription_products.fields.title")}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("subscription_products.fields.description")}
              name="description"
            >
              <Input.TextArea rows={6} />
            </Form.Item>
          </Form>
        </Modal>
        {children}
      </>
    </CanAccess>
  );
};

export default SubscriptionProductsCreate;
