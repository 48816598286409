import React from "react";
import { Modal, Form, Input, Checkbox } from "antd";
import { useUser } from "../../../contexts/ContextProvider";
import { supabaseClient } from "../../../utility";
import { useNotification, useTranslate } from "@refinedev/core";

const AddConnectionModal = ({ visible, onClose, form, appType, refetch }) => {
  const { currentTeam } = useUser();
  const { open: notify } = useNotification();
  const translate = useTranslate();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      type ConnectionDetails = {
        account: string | undefined;
        account_type: any;
        smtp_host?: any;
        smtp_port?: any;
        smtp_user?: any;
        smtp_pass?: any;
        smtp_secure?: any;
        iban?: any;
        bic?: any;
        name?: any;
        api_key?: any;
      };

      let connectionDetails: ConnectionDetails = {
        account: currentTeam?.account_id,
        account_type: appType,
      };

      if (appType === "smtp") {
        connectionDetails = {
          ...connectionDetails,
          smtp_host: values.smtp_host,
          smtp_port: values.smtp_port,
          smtp_user: values.smtp_user,
          smtp_pass: values.smtp_pass,
          smtp_secure: values.smtp_secure,
        };
      } else if (appType === "sepa") {
        connectionDetails = {
          ...connectionDetails,
          iban: values.iban,
          bic: values.bic,
          name: values.bic,
        };
      } else if (appType === "lexware_public") {
        connectionDetails = {
          ...connectionDetails,
          api_key: values.api_key,
        };
      }

      const { data, error } = await supabaseClient.functions.invoke(
        "integration-accounts/setup",
        { body: connectionDetails }
      );

      if (error) {
        notify?.({
          description: translate("alerts.error"),
          message: translate("settings.integrations.setup_failed"),
          type: "error",
        });
      } else {
        notify?.({
          description: translate("notifications.success"),
          message: translate("settings.integrations.setup_success"),
          type: "success",
        });
        refetch();
        onClose();
      }
    } catch (err) {
      console.error("Validation Failed:", err);
      notify?.({
        description: translate("alerts.error"),
        message: translate("alerts.validation_failed"),
        type: "error",
      });
    }
  };

  const renderFields = () => {
    if (appType === "smtp") {
      return (
        <>
          <Form.Item
              label={translate("integrations.smtp.host.label")}
              name="smtp_host"
              rules={[{ required: true, message: translate("integrations.smtp.host.error") }]}
          >
            <Input placeholder={translate("integrations.smtp.host.placeholder")} />
          </Form.Item>
          <Form.Item
              label={translate("integrations.smtp.port.label")}
              name="smtp_port"
              rules={[{ required: true, message: translate("integrations.smtp.port.error") }]}
          >
            <Input placeholder={translate("integrations.smtp.port.placeholder")} />
          </Form.Item>
          <Form.Item
              label={translate("integrations.smtp.user.label")}
              name="smtp_user"
              rules={[{ required: true, message: translate("integrations.smtp.user.error") }]}
          >
            <Input placeholder={translate("integrations.smtp.user.placeholder")} />
          </Form.Item>
          <Form.Item
              label={translate("integrations.smtp.password.label")}
              name="smtp_pass"
              rules={[{ required: true, message: translate("integrations.smtp.password.error") }]}
          >
            <Input.Password placeholder={translate("integrations.smtp.password.placeholder")} />
          </Form.Item>
          <Form.Item
              label={translate("integrations.smtp.secure_connection")}
              name="smtp_secure"
              valuePropName="checked"
          >
            <Checkbox>{translate("integrations.smtp.use_ssl")}</Checkbox>
          </Form.Item>
        </>
      );
    } else if (appType === "sepa") {
      return (
        <>
          <Form.Item
              label={translate("integrations.iban.iban.label")}
              name="iban"
              rules={[{ required: true, message: translate("integrations.iban.iban.error") }]}
          >
            <Input placeholder={translate("integrations.iban.iban.placeholder")} />
          </Form.Item>
          <Form.Item
              label={translate("integrations.iban.bic.label")}
              name="bic"
              rules={[{ required: true, message: translate("integrations.iban.bic.error") }]}
          >
            <Input placeholder={translate("integrations.iban.bic.placeholder")} />
          </Form.Item>
          <Form.Item
              label={translate("integrations.iban.account_name.label")}
              name="name"
              rules={[{ required: true, message: translate("integrations.iban.account_name.error") }]}
          >
            <Input placeholder={translate("integrations.iban.account_name.placeholder")} />
          </Form.Item>
        </>
      );
    } else if (appType === "lexware_public") {
      return (
          <Form.Item
              label={translate("integrations.lexware_public.label")}
              name="api_key"
              rules={[{ required: true, message: translate("integrations.lexware_public.error") }]}
          >
            <Input placeholder={translate("integrations.lexware_public.placeholder")} />
          </Form.Item>
      );
    }
    return null;
  };

  return (
    <Modal
      title={translate("integrations.add_connection")}
      open={visible}
      onCancel={onClose}
      onOk={handleOk}
      centered
    >
      <Form form={form} layout="vertical">
        {renderFields()}
      </Form>
    </Modal>
  );
};

export default AddConnectionModal;
