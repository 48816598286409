import { stat } from "fs";

export const getUserInviteErrorMessage = (
  t: (key: string, defaultMessage: string) => string,
  status: number
): string => {
  switch (status) {
    case 401:
      return t(
        "errors.invite.401",
        "Authorization header is missing. Please provide a valid token."
      );
    case 402:
      return t(
        "errors.invite.402",
        "You don't have permissions to invite a member."
      );
    case 405:
      return t(
        "errors.invite.405",
        "Method not allowed. Please check your request method."
      );
    case 406:
      return t(
        "errors.invite.406",
        "There was an error processing your invitation. Please try again."
      );
    case 407:
      return t(
        "errors.invite.407",
        "Error accepting the invitation. The user may not have been created."
      );
    case 500:
      return t(
        "errors.invite.500",
        "Server error occurred while processing the invite. Please try again."
      );
    case 510:
      return t(
        "errors.invite.510",
        "Error creating the invitation. Please try again later."
      );
    case 511:
      return t(
        "errors.invite.511",
        "Email is missing. Please provide a valid email address."
      );
    case 512:
      return t(
        "errors.invite.512",
        "Invalid email format. Please check and try again."
      );
    case 513:
      return t(
        "errors.invite.513",
        "Account role is missing. Please specify the role."
      );
    case 514:
      return t(
        "errors.invite.514",
        "Account role format is incorrect. Accepted values are 'owner' or 'member'."
      );
    default:
      return t(
        "errors.invite.default",
        "An unknown error occurred. Please try again."
      );
  }
};

export const getMailingErrorMessage = (
  t: (key: string, defaultMessage: string) => string,
  status: number
): string => {
  switch (status) {
    case 200:
      return t(
        "notifications.success_messages.mailing.200",
        "Action was successfull"
      );
    case 405:
      return t(
        "notifications.errors.mailing.405",
        "Method not allowed. Please check your request method."
      );
    case 500:
      return t(
        "notifications.errors.mailing.500",
        "An internal server error occurred. Please try again."
      );
    case 501:
      return t(
        "notifications.errors.mailing.501",
        "Failed to create the email. Please try again."
      );
    case 502:
      return t(
        "notifications.errors.mailing.502",
        "Failed to associate tags with the email. Please try again."
      );
    case 503:
      return t(
        "notifications.errors.mailing.503",
        "Failed to update the email. Please try again."
      );
    case 504:
      return t(
        "notifications.errors.mailing.504",
        "Failed to add new groups to the email. Please try again."
      );
    case 505:
      return t(
        "notifications.errors.mailing.505",
        "Failed to remove tags from the email. Please try again."
      );
    case 506:
      return t(
        "notifications.errors.mailing.506",
        "Failed to fetch contacts. Please try again."
      );
    case 507:
      return t(
        "notifications.errors.mailing.507",
        "Failed to send emails. Please try again."
      );
    case 508:
      return t(
        "notifications.errors.mailing.508",
        "Failed to update the send timestamp. Please try again."
      );
    case 509:
      return t(
        "notifications.errors.mailing.509",
        "Failed to fetch the sender email address. Please try again."
      );
    case 510:
      return t(
        "notifications.errors.mailing.510",
        "Failed to delete records. Please try again."
      );
    default:
      return t(
        "notifications.errors.mailing.default",
        "An unknown error occurred. Please try again."
      );
  }
};

export const getSepaErrorMessage = (
  t: (key: string, defaultMessage: string) => string,
  status: number
): string => {
  console.log("status", status);
  switch (status) {
    case 200:
      return t(
        "notifications.success_messages.sepa.200",
        "SEPA XML generated successfully."
      );
    case 204:
      return t(
        "notifications.success_messages.sepa.204",
        "SEPA XML partially generated!"
      );
    case 400:
      return t(
        "notifications.errors.sepa.400",
        "Missing 'account' query parameter. Please provide the account ID."
      );
    case 404:
      return t(
        "notifications.errors.sepa.404",
        "No finished invoices found for this account."
      );
    case 422:
      console.log(
        "qetu",
        t(
          "notifications.errors.sepa.422",
          "No invoices with valid bank details found."
        )
      );
      return t(
        "notifications.errors.sepa.422",
        "No invoices with valid bank details found."
      );
    case 500:
      return t(
        "notifications.errors.sepa.500",
        "An internal server error occurred. Please try again."
      );
    case 501:
      return t(
        "notifications.errors.sepa.501",
        "Failed to store the SEPA XML file. Please try again."
      );
    case 502:
      return t(
        "notifications.errors.sepa.502",
        "Failed to update invoice statuses. Please try again."
      );
    case 503:
      return t(
        "notifications.error.sepa.503",
        "No bank details for the Workspace has been found."
      );
    default:
      return t(
        "notifications.errors.sepa.default",
        "An unknown error occurred. Please try again."
      );
  }
};

export const getPaymentMethodErrorMessage = (
  t: (key: string, defaultMessage: string) => string,
  status: number
): string => {
  switch (status) {
    case 200:
      return t(
        "notifications.success_messages.payment_method.200",
        "Operation completed successfully."
      );
    case 400:
      return t(
        "notifications.errors.payment_method.400",
        "Invalid 'api-key' parameter provided."
      );
    case 501:
      return t(
        "notifications.errors.payment_method.501",
        "Missing required parameters: 'contact_id' or 'payment_method_id'."
      );
    case 502:
      return t(
        "notifications.errors.payment_method.502",
        "Failed to update the expiration date. Please try again."
      );
    case 503:
      return t(
        "notifications.errors.payment_method.503",
        "Failed to delete the payment method. Please try again."
      );
    case 500:
      return t(
        "notifications.errors.payment_method.500",
        "An internal server error occurred. Please try again."
      );
    default:
      return t(
        "notifications.errors.payment_method.default",
        "An unknown error occurred. Please try again."
      );
  }
};
