// SendMail.tsx
import { gray } from "@ant-design/colors";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Popover,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import edjsHTML from "editorjs-html";
import React, { useCallback, useEffect, useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import { supabaseClient } from "../../../utility";
import EditorComponent from "../../mailings/EditorComponent";
import { Text } from "../../text";

export const SendMailDisplay: React.FC<{ item: any }> = ({ item }) => {
  const translate = useTranslate();

  function handleMJMLConvert(value: any) {
    const edjsParser = edjsHTML();
    const html = edjsParser.parse(value);
    return (
      <div
        style={{ maxHeight: 300, overflow: "auto" }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  return (
    <>
      {item.config?.from ? (
        <>
          <Text size={"xs"} style={{ color: gray[4] }}>
            {translate("pipeline_automations.from")}
          </Text>
          <br /> <Text strong>{item.config?.from}</Text>
        </>
      ) : null}
      {item.config?.to ? (
        <>
          <br />
          <Text size={"xs"} style={{ color: gray[4] }}>
            {translate("pipeline_automations.to")}
          </Text>
          <br /> <Text strong>{item.config?.to}</Text>
        </>
      ) : null}
      {item.config?.cc ? (
        <>
          <br />
          <Text size={"xs"} style={{ color: gray[4] }}>
            CC
          </Text>
          <br /> <Text strong>{item.config?.cc}</Text>
        </>
      ) : null}
      {item.config?.bcc ? (
        <>
          <br />
          <Text size={"xs"} style={{ color: gray[4] }}>
            BCC
          </Text>
          <br /> <Text strong>{item.config?.bcc}</Text>
        </>
      ) : null}
      {item.config?.subject ? (
        <>
          <br />
          <Text size={"xs"} style={{ color: gray[4] }}>
            {translate("pipeline_automations.subject")}
          </Text>
          <br /> <Text strong>{item.config?.subject}</Text>
        </>
      ) : null}
      {item.config?.message ? (
        <>
          <br />
          <Text size={"xs"} style={{ color: gray[4] }}>
            {translate("pipeline_automations.message")}
          </Text>
          <br />
          {handleMJMLConvert(item.config?.message)}
        </>
      ) : null}
    </>
  );
};

export const SendMailForm: React.FC<{
  item: any;
  onSave: (config: any) => void;
  onCancel: () => void;
}> = ({ item, onSave, onCancel }) => {
  const [form] = Form.useForm();
  const { currentTeam } = useUser();
  const [showCcBcc, setShowCcBcc] = useState(false);
  const [fields, setFields] = useState([]);
  const { selectProps } = useSelect({
    resource: "email_sender_addresses",
    optionLabel: "email",
    optionValue: "email",
    filters: [
      {
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      },
    ],
  });
  const getTableColumns = useCallback(async () => {
    const data = await supabaseClient.rpc("get_contacts_table_columns", {
      p_account: currentTeam?.account_id,
    });

    setFields(
      data?.data?.map((item) =>
        item.column_name.replace(/->>.*/, `.${item.label}`)
      )
    );
  }, []);
  useEffect(() => {
    getTableColumns();
  }, []);
  const handleSubmit = async () => {
    const values = await form.validateFields();
    onSave(values);
  };
  const translate = useTranslate();

  useEffect(() => {
    if (!showCcBcc) {
      form.setFieldsValue({ cc: "", bcc: "" }); // Clear CC and BCC fields when hidden
    }
  }, [showCcBcc, form]);
  const handleJsonData = (jsonData: any) => {
    form.setFieldValue("message", jsonData);
  };
  return (
    <div>
      <Form form={form} layout="vertical" initialValues={item.config}>
        <Form.Item
          name="from"
          label={translate("pipeline_automations.from")}
          rules={[{ required: true }]}
        >
          <Select {...selectProps} />
        </Form.Item>
        <Form.Item
          name="to"
          label={translate("pipeline_automations.to")}
          extra={
            <>
              <Button
                size="small"
                type="link"
                onClick={() => setShowCcBcc(!showCcBcc)}
              >
                {showCcBcc ? "Hide CC/BCC" : "Show CC/BCC"}
              </Button>
            </>
          }
          rules={[{ required: true }]}
        >
          <Select
            mode="tags"
            placeholder="Enter recipient emails"
            style={{ width: "100%" }}
          />
        </Form.Item>
        {showCcBcc && (
          <>
            <Form.Item name="cc" label="CC">
              <Select mode="tags" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="bcc" label="BCC">
              <Select mode="tags" style={{ width: "100%" }} />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="subject"
          label={translate("pipeline_automations.subject")}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="message"
          label={
            <Space>
              {translate("pipeline_automations.message")}
              <Popover
                content={
                  <>
                    <Typography.Text>
                      You can use the following fields in your message by
                      inserting them with double curly braces. For example:{" "}
                      <Typography.Text code>{"{{firstname}}"}</Typography.Text>
                    </Typography.Text>
                    <div
                      style={{
                        maxHeight: 200,
                        overflowY: "auto",
                        marginTop: 8,
                        display: "flex",
                        flexWrap: "wrap",
                        maxWidth: 700,
                        gap: 10,
                      }}
                    >
                      {fields?.map((field) => (
                        <div key={field}>
                          <Tag>
                            <Typography.Text copyable>
                              {`{{${field}}}`}
                            </Typography.Text>
                          </Tag>
                        </div>
                      ))}
                    </div>
                  </>
                }
                title={translate("Available Fields")}
                trigger="hover"
              >
                <InfoCircleOutlined />
              </Popover>
            </Space>
          }
          rules={[{ required: true }]}
        >
          <EditorComponent
            initialValue={item?.config?.message}
            jsonValue={handleJsonData}
          />
          <Input.TextArea rows={4} hidden />
        </Form.Item>

        <Form.Item name="onNull" valuePropName="checked" initialValue={false}>
          <Checkbox>
            {translate("pipeline_automations.no_contacts_warning")}
          </Checkbox>
        </Form.Item>
      </Form>
      <div>
        <Space>
          <Button onClick={onCancel}>{translate("buttons.cancel")}</Button>
          <Button type="primary" onClick={handleSubmit}>
            {translate("buttons.save")}
          </Button>
        </Space>
      </div>
    </div>
  );
};
