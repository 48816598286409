import { FilePdfOutlined } from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import { useNavigation, useNotification, useTranslate } from "@refinedev/core";
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  List,
  message,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { UploadFileStatus } from "antd/es/upload/interface";
import { UploadFile } from "antd/lib";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useUser } from "../../../contexts/ContextProvider";
import { supabaseClient } from "../../../utility";

const SubscriptionContracts: React.FC<PropsWithChildren> = ({ children }) => {
  const [touch, setTouch] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState<any>(undefined);
  const [templates, setTemplates] = useState<any>([]);
  const [bucketFiles, setBucketFiles] = useState<UploadFile[]>([]);

  const { open } = useNotification();
  const translate = useTranslate();
  const { replace } = useNavigation();

  const { currentTeam, updateTeam } = useUser();

  const { form, formProps } = useForm({
    action: "edit",
  });
  async function openFile(fileItem: any) {
    const { data } = await supabaseClient.storage
      .from("files")
      .createSignedUrl(fileItem.path, 60 * 60);
    if (!data?.signedUrl) return;
    const link = document.createElement("a");
    link.href = data?.signedUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabaseClient.functions.invoke(
          "billing-functions",
          {
            body: {
              action: "get_new_subscription_url",
              args: {
                account_id: currentTeam?.account_id,
                // plan_id: "string",
                success_url: window.location.href,
                cancel_url: window.location.href,
              },
            },
          }
        );
        if (error) throw error;
        setSubscriptionData(data);
      } catch (error) {
        // setSubscriptionError(error);
      }
    };
    if (currentTeam?.account_id) {
      fetchData();
    }
  }, [currentTeam, currentTeam?.account_id]);
  const fetchBucketFiles = useCallback(async () => {
    try {
      const { data, error } = await supabaseClient.storage
        .from("files")
        .list(`teams/${currentTeam?.account_id}/dpa`, {
          sortBy: { column: "created_at", order: "desc" },
        });
      if (error) {
        throw error;
      }

      if (data) {
        const formattedFiles = data.map((file: any) => ({
          uid: file.id,
          name: file.name,
          status: "done" as UploadFileStatus,
          mimetype: file.metadata.mimetype,
          fullPath: `files/teams/${currentTeam?.account_id}/dpa/${file.name}`,
          path: `teams/${currentTeam?.account_id}/dpa/${file.name}`,
        }));
        setBucketFiles(formattedFiles);
      }
    } catch (error: any) {
      console.error(
        "Fehler beim Abrufen der Dateien im Bucket:",
        error.message
      );
      message.error("Fehler beim Abrufen der Dateien im Bucket");
    }
  }, [currentTeam?.account_id]);

  useEffect(() => {
    fetchBucketFiles();
  }, [fetchBucketFiles]);
  useEffect(() => {
    const fetchTemplates = async () => {
      const { data } = await supabaseClient.storage
        .from("files")
        .list("public/data_processing_agreements");
      if (data?.length) {
        setTemplates(data);
      }
    };

    fetchTemplates();
  }, []);
  async function generateNewDPA(lang: string, date: string) {
    try {
      const { data, error } = await supabaseClient.functions.invoke(
        "data_processing_agreements",
        {
          body: {
            account: currentTeam?.account_id,
            lang: lang,
          },
        }
      );
      if (error) {
        throw error;
      }

      const link = document.createElement("a");
      link.href = data;
      link.download = `${lang}_${
        new Date(date).toISOString().split("T")[0]
      }.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      fetchBucketFiles();
    } catch (error: any) {
      if (error.context.status == 502) {
        open?.({
          description: translate("alerts.toast.error"),
          message: translate("alerts.dpa.fill_company_details"),
          type: "error",
        });
      } else if (error.context.status == 409) {
        open?.({
          description: translate("alerts.toast.error"),
          message: translate("alerts.dpa.latest_exists"),
          type: "error",
        });
      } else {
        open?.({
          description: translate("alerts.submitError.title"),
          message: translate("alerts.submitError.defaultMessage"),
          type: "error",
        });
      }
    }
  }
  const handleSubmit = async () => {
    try {
      const validate = await form.validateFields();
      if (validate) {
        const details = form.getFieldsValue();
        try {
          if (currentTeam?.account_id && details) {
            await updateTeam(currentTeam.account_id, {
              public_metadata: { ...currentTeam.metadata, details },
            });
          }
          setTouch(false);
          formProps.onFinish?.({});
        } catch (error) {
          console.error("Error updating team", error);
        }
      }
    } catch (error) {
      console.error("Error updating team", error);
    }
  };
  if (!currentTeam) {
    return <Spin />;
  }

  return (
    <>
      <Row>
        <Col md={8}>
          <Typography.Title level={4}>
            {translate("settings.subscriptions_contracts")}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {translate("settings.subscriptions_contracts_intro")}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Form
        {...formProps}
        onFieldsChange={() => setTouch(true)}
        form={form}
        initialValues={currentTeam?.metadata?.details}
        layout="vertical"
        style={{ marginTop: 50 }}
      >
        <Row gutter={20}>
          <Col md={8}>
            <Typography.Title level={5}>
              {translate("subscriptions.list.title")}
            </Typography.Title>
            <Typography.Paragraph type="secondary">
              {translate("subscriptions.list.see_subscriptions")}
            </Typography.Paragraph>
          </Col>
          <Col md={16}>
            <Descriptions layout="vertical" colon={false} size="small">
              <Descriptions.Item
                label={translate(
                  "teams.no_billing_status",
                  "No Billing Status"
                )}
              >
                <Button href={subscriptionData?.url}>
                  {translate("teams.start_subscription", "Start Subscription")}
                </Button>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row gutter={20} style={{ marginTop: 50 }}>
          <Col md={8}>
            <Typography.Title level={5}>
              {translate("subscriptions.contract_partner_billing_address")}
            </Typography.Title>
            <Typography.Paragraph type="secondary">
              {translate("subscriptions.contract_partner_info")}
            </Typography.Paragraph>
          </Col>
          <Col md={16}>
            <Row gutter={[20, 0]} align={"bottom"}>
              <Col md={16}>
                <Form.Item
                  name="company_name"
                  label={translate("teams.company_name")}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Company name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  label={translate("billing_details.vat_number")}
                  rules={[
                    {
                      required: true,
                      message: "Please input your VAT Number",
                    },
                  ]}
                  name={"vat_number"}
                  required
                  tooltip={
                    <Typography.Text style={{ color: "white" }}>
                      <Trans i18nKey={"billing_details.vat_description"}>
                        <strong></strong>
                      </Trans>
                    </Typography.Text>
                  }
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item
                  label={translate("billing_details.street_address", {
                    n: 1,
                  })}
                  name={"address"}
                  tooltip={translate("billing_details.address_description")}
                  rules={[
                    { required: true, message: "Please input your address!" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={translate("billing_details.street_address", {
                    n: 2,
                  })}
                  name={"second_address"}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={translate("billing_details.city")}
                  name={"city"}
                  rules={[
                    { required: true, message: "Please input your City" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={translate("billing_details.postal_code")}
                  required
                  name={"postal_code"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Postal Code",
                    },
                    () => ({
                      validator(_, value) {
                        if (!isNaN(Number(value))) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Postal Code must be a number")
                        );
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  label={translate("billing_details.state_region")}
                  name={"state"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your State / Region",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col md={12}>
                <Form.Item
                  label={translate("billing_details.country")}
                  name={"country"}
                  rules={[
                    { required: true, message: "Please input your City" },
                  ]}
                >
                  <Select
                    placeholder={translate(
                      "billing_details.country_placeholder"
                    )}
                    options={[
                      { value: "de", label: translate("countries.DE") },
                      { value: "at", label: translate("countries.AT") },
                      { value: "ch", label: translate("countries.CH") },
                      { value: "us", label: translate("countries.US") },
                      { value: "fr", label: translate("countries.FR") },
                      { value: "uk", label: translate("countries.UK") },
                      { value: "es", label: translate("countries.ES") },
                      { value: "it", label: translate("countries.IT") },
                    ]}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={20} style={{ marginTop: 50 }}>
          <Col md={8}>
            <Typography.Title level={5}>
              {translate("billing_details.billing_email")}
            </Typography.Title>
            <Typography.Paragraph type="secondary">
              {translate("billing_details.email_info")}
            </Typography.Paragraph>
          </Col>
          <Col md={16}>
            <Row gutter={[20, 0]}>
              <Col md={24}>
                <Form.Item
                  label={translate("billing_details.email")}
                  tooltip={translate("billing_details.email_description")}
                  name={"email"}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Row gutter={20} style={{ marginTop: 50 }}>
        <Col md={8}>
          <Typography.Title level={5}>
            {translate("billing_details.dpa")}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {translate("billing_details.dpa_info")}
          </Typography.Paragraph>
        </Col>
        <Col md={16}>
          {bucketFiles.length ? (
            <>
              <List
                size="small"
                itemLayout="horizontal"
                dataSource={bucketFiles}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<FilePdfOutlined />}
                      title={
                        <Typography.Link
                          key={item.uid}
                          onClick={() => openFile(item)}
                        >
                          {item.name}
                        </Typography.Link>
                      }
                    />
                  </List.Item>
                )}
              />
              <Space style={{ marginTop: 20 }}>
                {templates?.map((template) => {
                  // Extrahiere die Sprache
                  const langCode = template.name.split(".")?.[0];

                  // Verwende switch-case, um den Sprachcode umzuwandeln
                  let language;
                  switch (langCode) {
                    case "de":
                      language = translate("page.settings.german");
                      break;
                    case "en":
                      language = translate("page.settings.english");
                      break;
                    default:
                      language = langCode; // Fallback auf den ursprünglichen Text
                  }

                  return (
                    <Button
                      key={template.id}
                      onClick={() =>
                        generateNewDPA(langCode, template.created_at)
                      }
                      disabled={bucketFiles.some((file) =>
                        file.name.startsWith(langCode)
                      )}
                    >
                      {language}
                    </Button>
                  );
                })}
              </Space>
            </>
          ) : null}
        </Col>
      </Row>
      <Row justify="start">
        <Col>
          <Space>
            <Button
              onClick={() => {
                close();
                replace("/workspaces");
              }}
            >
              {!touch
                ? translate("buttons.close")
                : translate("buttons.cancel")}
            </Button>

            {touch && (
              <Button type="primary" disabled={!touch} onClick={handleSubmit}>
                {translate("buttons.save")}
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      {children}
    </>
  );
};
export default SubscriptionContracts;
