import React from "react";
import SettingsItem from "./components/SettingsItem";
import SettingsContainer from "./components/SettingsContainer";
import { useSettingsLogic } from "./hooks/useSettingsLogic";
import {Input} from "antd";

const SettingsPage: React.FC = () => {
  const { settings, updateModuleStatus, translate } = useSettingsLogic({
    modulePath: "shop",
  });

  return (
    <SettingsContainer
      modulePath="shop"
      title={translate("settings.shop.title")}
      description={translate("settings.shop.description")}
      intro={translate("settings.shop.intro")}
    >

      <SettingsItem
        title={translate("settings.shop.variants.title")}
        description={translate("settings.shop.variants.description")}
        checked={settings["shop.variants"]}
        onChange={() =>
          updateModuleStatus("shop.variants", !settings["shop.variants"])
        }
      />

      <SettingsItem
        title={translate("settings.shop.ecommerce.title")}
        description={translate("settings.shop.ecommerce.description")}
        checked={settings["shop.ecommerce"]}
        onChange={() =>
          updateModuleStatus("shop.ecommerce", !settings["shop.ecommerce"])
        }
      >

      </SettingsItem>

      <SettingsItem
        title={translate("settings.shop.selfservice.title")}
        description={translate("settings.shop.selfservice.description")}
        checked={settings["shop.selfservice"]}
        onChange={() =>
          updateModuleStatus("shop.selfservice", !settings["shop.selfservice"])
        }
      />

      <SettingsItem
        title={translate("settings.shop.subscriptions.title")}
        description={translate("settings.shop.subscriptions.description")}
        checked={settings["shop.subscriptions"]}
        onChange={() =>
          updateModuleStatus("shop.subscriptions", !settings["shop.subscriptions"])
        }
      />

      {settings["shop.subscriptions"] && (
        <>
          <SettingsItem
            title={translate("settings.shop.subscriptions.issue.title")}
            description={translate("settings.shop.subscriptions.issue.description")}
            checked={settings["shop.issue"]}
            onChange={() =>
              updateModuleStatus("shop.issue", !settings["shop.issue"])
            }
          />
        </>
      )}

    </SettingsContainer>
  );
};

export default SettingsPage;