import { useForm } from "@refinedev/antd";
import {
  BaseKey,
  useNavigation,
  useResourceParams,
  useTranslate,
} from "@refinedev/core";
import {
  Checkbox,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import React, { PropsWithChildren, useEffect, useState } from "react";
import FilterGroup from "../../components/contacts/filter-group";
import { useUser } from "../../contexts/ContextProvider";

interface Props extends PropsWithChildren {
  edit?: any;
  openEdit?: boolean;
  onCloseEdit?: () => void;
}

type FrequencyOption = "daily" | "weekly" | "monthly" | "yearly";

export default function AutomationCreateUpdate({
  children,
  edit,
  openEdit,
  onCloseEdit,
}: Props) {
  const { currentTeam } = useUser();
  const { list } = useNavigation();
  const { id } = useResourceParams();
  const [basedOn, setBasedOn] = useState(null);
  const [whenToStart, setWhenToStart] = useState(null);
  const [groups, setGroups] = useState<BaseKey[]>();
  const [segment, setSegment] = useState();
  const [frequency, setFrequency] = useState<FrequencyOption>();
  // const { RangePicker } = DatePicker;
  const { Option } = Select;
  const translate = useTranslate();

  const tooltipText = translate("automations.when_to_start_tooltip")
    .split("\n")
    .map((line, index) => (
      <React.Fragment key={index}>
        {line
          .split(/(<b>.*?<\/b>)/g)
          .map((part, idx) =>
            part.startsWith("<b>") && part.endsWith("</b>") ? (
              <b key={idx}>{part.replace(/<\/?b>/g, "")}</b>
            ) : (
              <span key={idx}>{part}</span>
            )
          )}
        <br />
      </React.Fragment>
    ));

  const onFrequencyChange = (value: FrequencyOption) => {
    setFrequency(value);
  };

  // const onDateRangeChange = (
  //   dates: [Dayjs | null, Dayjs | null] | null,
  //   dateStrings: [string, string]
  // ) => {
  //   console.log(dates, dateStrings);
  // };

  const { formProps, saveButtonProps } = useForm({
    resource: "automations",
    action: edit ? "edit" : "create",
    id,
    redirect: "edit",
  });

  useEffect(() => {
    const initialBasedOn = formProps?.initialValues?.based_on;
    const initialWhenToStart = formProps?.initialValues?.frequency?.whenToStart;
    const initialFrequency = formProps?.initialValues?.frequency?.type;
    const initialGroups = formProps?.initialValues?.criterias?.groups;
    const initialSegment = formProps?.initialValues?.criterias?.segment;

    if (initialBasedOn) {
      setBasedOn(initialBasedOn);
    }
    if (initialWhenToStart) {
      setWhenToStart(initialWhenToStart);
    }
    if (initialFrequency) {
      setFrequency(initialFrequency);
    }
    if (initialGroups) {
      setGroups(initialGroups);
    }
    if (initialSegment) {
      setSegment(initialSegment);
    }
  }, [formProps.initialValues]);

  const FilterGroupComponent = (
    <FilterGroup
      key={JSON.stringify(formProps?.initialValues?.criterias?.segment)}
      handleGroups={(selectedGroup) => setGroups(selectedGroup)}
      handleSegments={(selectedSegments) => {
        setSegment(selectedSegments);
      }}
      groupsValue={groups}
      initialSegmentValue={formProps?.initialValues?.criterias?.segment}
      showInWeek
    />
  );

  return (
    <>
      <Modal
        open={edit ? openEdit : true}
        okButtonProps={{
          ...saveButtonProps,
        }}
        onCancel={() => {
          if (edit) {
            formProps.form?.resetFields();
            onCloseEdit && onCloseEdit();
          } else {
            list("automations");
          }
        }}
        title={`${
          edit
            ? translate("automations.titles.edit")
            : translate("automations.titles.create")
        }`}
        centered
        styles={{
          body: {
            overflowY: "auto",
            maxHeight: "70dvh",
          },
        }}
      >
        <Form
          {...formProps}
          onFinish={(values: any) => {
            const vel = {
              name: values?.name,
              based_on: values?.based_on,
              criterias: {
                groups: groups,
                segment: segment,
              },
              frequency: values?.frequency,
              account: currentTeam?.account_id,
            };
            return formProps.onFinish && formProps?.onFinish(vel);
          }}
          layout="vertical"
          style={{ marginBottom: edit ? -25 : "auto" }}
        >
          <Form.Item
            name="name"
            label={translate("automations.name")}
            rules={[
              {
                required: true,
                message: translate("automations.name_required"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="based_on"
            label={translate("automations.based_on")}
            rules={[
              {
                required: true,
                message: translate("automations.based_required"),
              },
            ]}
          >
            <Select
              onChange={(value) => setBasedOn(value)}
              options={[
                { label: translate("contacts.contacts"), value: "contacts" },
              ]}
            />
          </Form.Item>

          {basedOn === "contacts" && (
            <>
              <Form.Item
                name={["frequency", "whenToStart"]}
                label={translate("automations.when_to_start")}
                rules={[
                  {
                    required: true,
                    message: translate("automations.when_to_start_required"),
                  },
                ]}
                tooltip={<span>{tooltipText}</span>}
              >
                <Select
                  onChange={(value) => setWhenToStart(value)}
                  options={[
                    {
                      label: translate("automations.when_to_start_scheduled"),
                      value: "scheduled",
                    },
                    {
                      label: translate(
                        "automations.when_to_start_certain_date"
                      ),
                      value: "certain_date",
                    },
                    {
                      label: translate("automations.when_to_start_date_field"),
                      value: "date_field",
                    },
                    {
                      label: translate(
                        "automations.when_to_start_event_triggered"
                      ),
                      value: "event_triggered",
                    },
                  ]}
                />
              </Form.Item>

              {whenToStart === "scheduled" && (
                <Form.Item name="frequency">
                  <Form.Item
                    label={translate("automations.when_to_start_frequency")}
                    name={["frequency", "type"]}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          "automations.when_to_start_frequency_text"
                        ),
                      },
                    ]}
                  >
                    <Select value={frequency} onChange={onFrequencyChange}>
                      <Option value="daily">
                        {translate("automations.when_to_start_daily")}
                      </Option>
                      <Option value="weekly">
                        {translate("automations.when_to_start_weekly")}
                      </Option>
                      <Option value="monthly">
                        {translate("automations.when_to_start_monthly")}
                      </Option>
                      <Option value="yearly">
                        {translate("automations.when_to_start_yearly")}
                      </Option>
                    </Select>
                  </Form.Item>

                  {(frequency === "daily" || frequency === "weekly") && (
                    <Form.Item
                      label={translate("automations.when_to_start_time")}
                      name={["frequency", "time"]}
                      rules={[
                        {
                          required: true,
                          message: translate(
                            "automations.when_to_start_time_text"
                          ),
                        },
                      ]}
                      getValueProps={(value) => ({
                        value: value && dayjs(value),
                      })}
                    >
                      <TimePicker format="HH:mm" />
                    </Form.Item>
                  )}

                  {frequency === "weekly" && (
                    <Form.Item
                      label={translate("automations.when_to_start_days")}
                      name={["frequency", "daysOfWeek"]}
                      rules={[
                        {
                          required: true,
                          message: translate(
                            "automations.when_to_start_days_text"
                          ),
                        },
                      ]}
                    >
                      <Checkbox.Group
                        options={[
                          {
                            label: translate("global.monday"),
                            value: "monday",
                          },
                          {
                            label: translate("global.tuesday"),
                            value: "tuesday",
                          },
                          {
                            label: translate("global.wednesday"),
                            value: "wednesday",
                          },
                          {
                            label: translate("global.thursday"),
                            value: "thursday",
                          },
                          {
                            label: translate("global.friday"),
                            value: "friday",
                          },
                          {
                            label: translate("global.saturday"),
                            value: "saturday",
                          },
                          {
                            label: translate("global.sundayDu"),
                            value: "sunday",
                          },
                        ]}
                      />
                    </Form.Item>
                  )}
                  <Flex>
                    {(frequency === "monthly" || frequency === "yearly") && (
                      <Form.Item
                        label={translate("automations.when_to_start_day_month")}
                        name={["frequency", "day"]}
                        rules={[
                          {
                            required: true,
                            message: translate(
                              "automations.when_to_start_day_month_text"
                            ),
                          },
                          {
                            type: "number",
                            min: 1,
                            max: 31,
                            message: translate(
                              "automations.when_to_start_valid_day"
                            ),
                          },
                        ]}
                      >
                        <InputNumber defaultValue={15} min={1} max={31} />
                      </Form.Item>
                    )}

                    {(frequency === "weekly" || frequency === "monthly") && (
                      <Form.Item
                        label={translate("automations.when_to_start_every")}
                        name={["frequency", "interval"]}
                        extra={
                          frequency === "weekly"
                            ? translate("automations.when_to_start_weeks2")
                            : translate("automations.when_to_start_months2")
                        }
                        rules={[
                          {
                            required: true,
                            message: `${translate(
                              "automations.when_to_start_every_text"
                            )} ${
                              frequency === "weekly"
                                ? translate("automations.when_to_start_weeks")
                                : translate("automations.when_to_start_months")
                            }`,
                          },
                        ]}
                        initialValue={1}
                      >
                        <InputNumber min={1} />
                      </Form.Item>
                    )}

                    {frequency === "yearly" && (
                      <Form.Item
                        label={translate("automations.when_to_start_month")}
                        style={{ flex: 0.5 }}
                        name={["frequency", "month"]}
                        rules={[
                          {
                            required: true,
                            message: translate(
                              "automations.when_to_start_month_text"
                            ),
                          },
                        ]}
                      >
                        <Select
                          options={[
                            {
                              label: translate("global.january"),
                              value: "january",
                            },
                            {
                              label: translate("global.february"),
                              value: "february",
                            },
                            {
                              label: translate("global.march"),
                              value: "march",
                            },
                            {
                              label: translate("global.april"),
                              value: "april",
                            },
                            { label: translate("global.may"), value: "may" },
                            { label: translate("global.june"), value: "june" },
                            { label: translate("global.july"), value: "july" },
                            {
                              label: translate("global.august"),
                              value: "august",
                            },
                            {
                              label: translate("global.september"),
                              value: "september",
                            },
                            {
                              label: translate("global.october"),
                              value: "october",
                            },
                            {
                              label: translate("global.november"),
                              value: "november",
                            },
                            {
                              label: translate("global.december"),
                              value: "december",
                            },
                          ]}
                        />
                      </Form.Item>
                    )}
                  </Flex>
                </Form.Item>
              )}

              {whenToStart === "certain_date" && (
                <Form.Item
                  name={["frequency", "certainDate"]}
                  label={translate("automations.when_to_start_date_time")}
                  getValueProps={(value) => ({
                    value: value && dayjs(Number(value)),
                  })}
                  normalize={(value) => value && `${dayjs(value).valueOf()}`}
                >
                  <DatePicker
                    showTime
                    format={{ format: translate("global.date_time_format") }}
                  />
                </Form.Item>
              )}

              {whenToStart === "date_field" && (
                <Form.Item
                  name={["frequency", "dateField"]}
                  label={translate("automations.when_to_start_select_date")}
                  rules={[
                    {
                      required: true,
                      message: translate(
                        "automations.when_to_start_select_date_text"
                      ),
                    },
                  ]}
                >
                  <Select
                    options={[
                      {
                        label: translate("global.created_at"),
                        value: "created_at",
                      },
                      {
                        label: translate("global.updated_at"),
                        value: "updated_at",
                      },
                      // Add other date fields here
                    ]}
                  />
                </Form.Item>
              )}

              {whenToStart === "event_triggered" && (
                <Form.Item
                  name={["frequency", "event"]}
                  label={translate("automations.when_to_start_select_event")}
                  rules={[
                    {
                      required: true,
                      message: translate(
                        "automations.when_to_start_select_event_text"
                      ),
                    },
                  ]}
                >
                  <Select
                    options={[
                      {
                        label: translate(
                          "automations.when_to_start_event_create"
                        ),
                        value: "INSERT",
                      },
                      {
                        label: translate(
                          "automations.when_to_start_event_update"
                        ),
                        value: "UPDATE",
                      },
                      {
                        label: translate(
                          "automations.when_to_start_event_delete"
                        ),
                        value: "DELETE",
                      },
                    ]}
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Modal>
      {children}
    </>
  );
}
