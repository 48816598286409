import { useState, useEffect } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import { usePipelineContext } from "../../../contexts/PipelineProvider";
import { useNotification, useTranslate } from "@refinedev/core";
import { getNestedValue, setNestedValue } from "../components/ObjectUtils";

interface UseSettingsLogicProps {
  modulePath: string; // Path to the module, e.g., "shop"
}

export const useSettingsLogic = ({ modulePath }: UseSettingsLogicProps) => {
  const [settings, setSettings] = useState<Record<string, any>>({});
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false); // Prevent overwrites during updates
  const { canAccess, setCanAccess } = usePipelineContext();
  const { currentTeam, updateTeam, refetchTeams } = useUser();
  const { open } = useNotification();
  const translate = useTranslate(); // Translation function

  useEffect(() => {
    if (!isUpdating) {
      const moduleSettings = getNestedValue(
        currentTeam || {},
        `metadata.module.${modulePath}`,
        {}
      );

      // Passe die Struktur an, damit die Keys die vollständigen Pfade enthalten (z. B. "shop.enabled")
      const flattenedSettings: Record<string, any> = {};
      Object.entries(moduleSettings || {}).forEach(([key, value]) => {
        flattenedSettings[`${modulePath}.${key}`] = value;
      });

      setSettings(flattenedSettings || {});
    }
  }, [currentTeam, modulePath, isUpdating]);

  const updateModuleStatus = async (path: string, value: any) => {
    if (!currentTeam) return;

    setIsUpdating(true); // Verhindere, dass der State während des Updates überschrieben wird

    // Extrahiere den `modulePath` aus dem Key (z. B. "shop.enabled" → "shop")
    const [module, ...restPathParts] = path.split(".");
    const nestedPath = restPathParts.join("."); // Restlicher Pfad nach dem Modulnamen

    // Clone das module-Objekt für eine korrekte Aktualisierung
    const updatedModules = { ...currentTeam.metadata?.module };

    setNestedValue(updatedModules, `${module}.${nestedPath}`, value);

    try {
      if (currentTeam.account_id) {
        // Sende nur das aktualisierte `module`-Objekt an den Server
        await updateTeam(currentTeam.account_id, {
          public_metadata: {
            ...currentTeam.metadata,
            module: updatedModules,
          },
        });
      }

      setCanAccess({ ...canAccess, personal_modules: updatedModules });
      refetchTeams();

      open?.({
        description: translate("notifications.success"),
        message: translate("notifications.editSuccess"),
        type: "success",
      });

      // Aktualisiere die `settings` mit dem vollständigen Pfad
      setSettings((prevSettings) => ({
        ...prevSettings,
        [path]: value,
      }));

      if (path.endsWith(".enabled")) {
        setPopoverVisible(false);
      }
    } catch (error) {
      // console.error("Error updating module:", error);
      open?.({
        description: translate("notifications.error"),
        message: translate("notifications.editFailed"),
        type: "error",
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    settings,
    popoverVisible,
    setPopoverVisible,
    updateModuleStatus,
    translate,
  };
};