import { useSelect } from "@refinedev/antd";
import { useUser } from "../contexts/ContextProvider";
import { IPhase } from "../interfaces/phase.interface";

export const useSubscriptionProductSelect = () => {
  const { currentTeam } = useUser();

  return useSelect<{
    id: number | string;
    name: string;
    description?: string;
    phases?: IPhase[];
    account: string;
    product_item: any;
  }>({
    resource: "subscription_products",
    // optionLabel: "firstname"| ,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "account",
        value:
          currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
        operator: "eq",
      },
    ],
    meta: {
      select: "*, product_item(title)",
    },
  });
};
