import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  PlusOutlined,
  RedoOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { BaseKey, useTranslate, useUpdate } from "@refinedev/core";
import {
  Button,
  Card,
  Drawer,
  Dropdown,
  Flex,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Space,
  Timeline,
  Typography,
} from "antd";
import { CSSProperties, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import FilterGroup from "../contacts/filter-group";
import {
  MoveToPipelineDisplay,
  MoveToPipelineForm,
} from "./nodes-v2/MoveToPipeline";
import { SendMailDisplay, SendMailForm } from "./nodes-v2/SendMail";
import { SendWebhookDisplay, SendWebhookForm } from "./nodes-v2/SendWebhook";

const { Text } = Typography;

const SortableItem = ({
  item,
  timeBlockIndex,
  blockIndex,
  handleActionClick,
  handleDeleteItem,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    marginBottom: 8,
  };

  const translate = useTranslate();

  const renderDisplayComponent = (translate) => {
    let DisplayComponent;
    switch (item.name) {
      case "webhook":
        DisplayComponent = SendWebhookDisplay;
        break;
      case "send_email":
        DisplayComponent = SendMailDisplay;
        break;
      case "move_pipeline":
        DisplayComponent = MoveToPipelineDisplay;
        break;
      default:
        DisplayComponent = null;
    }
    return (
      <Card
        size="small"
        onClick={() => handleActionClick(item, timeBlockIndex, blockIndex)}
        title={translate(`automations.${item?.name}`)}
        extra={
          <Popconfirm
            title={translate("pipeline_automations.confirm_delete_item")}
            onConfirm={(e: any) => {
              e.stopPropagation();
              handleDeleteItem(item.id, timeBlockIndex, blockIndex);
            }}
            okText={translate("pipeline_automations.yes")}
            cancelText={translate("pipeline_automations.no")}
            onCancel={(e: any) => e.stopPropagation()}
          >
            <DeleteOutlined
              onClick={(e) => e.stopPropagation()}
              style={{ color: "red" }}
            />
          </Popconfirm>
        }
        styles={{
          body: {
            display:
              Object.keys(item.config).length === 0 ? "none" : "inline-block",
          },
        }}
      >
        {DisplayComponent ? (
          <DisplayComponent item={item} />
        ) : (
          <Text>No display component available.</Text>
        )}
      </Card>
    );
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {renderDisplayComponent(translate)}
    </div>
  );
};

const AutomationTimeline = ({ data, onSave, page }) => {
  const isOldFormat = (data) => {
    return (
      Array.isArray(data) &&
      data.some((item) => item.name && item.path && item.type)
    );
  };
  const [blocks, setBlocks] = useState(() => {
    return isOldFormat(data) ? [] : Array.isArray(data) ? data : [];
  });

  const [initialBlocks, setInitialBlocks] = useState(() => {
    return isOldFormat(data) ? [] : Array.isArray(data) ? data : [];
  });
  const [history, setHistory] = useState<any[]>([]);
  const [future, setFuture] = useState<any[]>([]);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState<any>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [currentTimeBlockIndex, setCurrentTimeBlockIndex] = useState(null);
  const [currentBlockIndex, setCurrentBlockIndex] = useState(null);
  const [startTime, setStartTime] = useState<any>({ number: 1, type: "day" });
  const [isConditionModalVisible, setConditionModalVisible] = useState(false);
  const [saveLoadingButton, setSaveLoadingButton] = useState(false);
  const [groups, setGroups] = useState<BaseKey[]>();
  const [segment, setSegment] = useState([]);

  const openConditionModal = (timeBlockIndex, blockIndex) => {
    setCurrentTimeBlockIndex(timeBlockIndex);
    setCurrentBlockIndex(blockIndex);
    const block = blocks[timeBlockIndex].blocks[blockIndex];
    setGroups(block.conditions?.groups || []);
    setSegment(block.conditions?.segment || []);
    setConditionModalVisible(true);
  };
  const { mutate: update } = useUpdate();
  const translate = useTranslate();
  const params = useParams();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const timeUnits = { immediately: 0, day: 1, week: 7, month: 30, year: 365 }; // approximate days

  const sortBlocks = (blocksToSort) => {
    return [...blocksToSort].sort((a, b) => {
      const aTime = timeUnits[a.start.type] * a.start.number;
      const bTime = timeUnits[b.start.type] * b.start.number;
      return aTime - bTime;
    });
  };

  const modifyBlocks = (modifier) => {
    setBlocks((prevBlocks) => {
      const newBlocks = modifier(prevBlocks);
      setHistory([...history, prevBlocks]);
      setFuture([]);
      return newBlocks;
    });
  };

  const onDragEnd = (event, timeBlockIndex, blockIndex) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      modifyBlocks((prevBlocks) => {
        const newBlocks = [...prevBlocks];
        const items = newBlocks[timeBlockIndex].blocks[blockIndex].items;
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        newBlocks[timeBlockIndex].blocks[blockIndex].items = arrayMove(
          items,
          oldIndex,
          newIndex
        );
        return newBlocks;
      });
    }
  };

  const handleActionClick = (item, timeBlockIndex, blockIndex) => {
    setCurrentItem({ item, timeBlockIndex, blockIndex });
    setDrawerVisible(true);
  };

  const handleDeleteBlock = (timeBlockIndex, blockIndex) => {
    modifyBlocks((prevBlocks) => {
      const newBlocks = [...prevBlocks];
      if (newBlocks[timeBlockIndex].blocks.length > 1) {
        newBlocks[timeBlockIndex].blocks.splice(blockIndex, 1);
      } else {
        newBlocks.splice(timeBlockIndex, 1);
      }
      return newBlocks;
    });
  };

  const handleAddAction = (key, timeBlockIndex, blockIndex) => {
    const newItem = { id: uuidv4(), name: key, config: {} };
    modifyBlocks((prevBlocks) => {
      const newBlocks = [...prevBlocks];
      newBlocks[timeBlockIndex].blocks[blockIndex].items.push(newItem);
      return newBlocks;
    });
  };

  const actionMenu = (timeBlockIndex, blockIndex) => ({
    items: [
      {
        key: "send_email",
        label: translate("pipeline_automations.send_email"),
      },
      { key: "webhook", label: "Webhook" },
      ...(page == "pipelines"
        ? [
            {
              key: "move_pipeline",
              label: translate("pipeline_automations.move_to_pipeline"),
            },
          ]
        : []),
    ],
    onClick: ({ key }) => handleAddAction(key, timeBlockIndex, blockIndex),
  });
  const blockActionMenu = (timeBlockIndex, blockIndex, length) => ({
    items: [
      {
        label: (
          <Space
            style={{ color: blockIndex === 0 ? "gray" : "inherit" }}
            onClick={() => moveBlockUp(timeBlockIndex, blockIndex)}
          >
            <ArrowUpOutlined />
            {translate("pipeline_automations.move_up")}
          </Space>
        ),
        key: "move_up",
      },
      {
        label: (
          <Space
            style={{
              color:
                blockIndex === blocks[timeBlockIndex].blocks.length - 1
                  ? "gray"
                  : "inherit",
            }}
            onClick={() => moveBlockDown(timeBlockIndex, blockIndex)}
          >
            <ArrowDownOutlined />
            {translate("pipeline_automations.move_down")}
          </Space>
        ),
        key: "move_down",
      },
      {
        label: (
          <Popconfirm
            title={translate("pipeline_automations.delete_block")}
            description={translate("pipeline_automations.confirm_delete_block")}
            onConfirm={() => handleDeleteBlock(timeBlockIndex, blockIndex)}
            okText={translate("pipeline_automations.yes")}
            cancelText={translate("pipeline_automations.no")}
          >
            <Space style={{ color: "red" }}>
              <DeleteOutlined />
              {translate("pipeline_automations.delete_block")}
            </Space>
          </Popconfirm>
        ),
        key: "delete_block",
        style: {
          color: timeBlockIndex > 1 ? "red" : "gray",
        },
      },
    ],
  });

  const openTimeModal = (timeBlockIndex) => {
    setCurrentTimeBlockIndex(timeBlockIndex);
    const timeBlock = blocks[timeBlockIndex];
    setStartTime(timeBlock.start);
    setModalVisible(true);
  };

  const handleModalOk = () => {
    if (currentTimeBlockIndex !== null) {
      modifyBlocks((prevBlocks) => {
        const newBlocks = [...prevBlocks];
        newBlocks[currentTimeBlockIndex].start = { ...startTime };
        return sortBlocks(newBlocks);
      });
      setModalVisible(false);
    }
  };

  const addBlock = (timeBlockIndex) => {
    const newBlock = {
      conditions: {
        groups: [],
        segment: [],
      },
      items: [],
      id: uuidv4(),
    };
    modifyBlocks((prevBlocks) => {
      const newBlocks = [...prevBlocks];
      newBlocks[timeBlockIndex].blocks.push(newBlock);
      return newBlocks;
    });
  };

  const addTimeBlock = () => {
    const newTimeBlock = {
      id: uuidv4(),
      start: { number: 1, type: "day" },
      blocks: [
        {
          conditions: {
            groups: [],
            segment: [],
          },
          items: [],
        },
      ],
    };
    modifyBlocks((prevBlocks) => sortBlocks([...prevBlocks, newTimeBlock]));
  };

  const handleDeleteItem = (itemId, timeBlockIndex, blockIndex) => {
    modifyBlocks((prevBlocks) => {
      const newBlocks = [...prevBlocks];
      newBlocks[timeBlockIndex].blocks[blockIndex].items = newBlocks[
        timeBlockIndex
      ].blocks[blockIndex].items.filter((item) => item.id !== itemId);
      return newBlocks;
    });
  };

  const handleSaveConfig = (config) => {
    modifyBlocks((prevBlocks) => {
      const newBlocks = [...prevBlocks];
      const { item, timeBlockIndex, blockIndex } = currentItem;
      newBlocks[timeBlockIndex].blocks[blockIndex].items = newBlocks[
        timeBlockIndex
      ].blocks[blockIndex].items.map((it) => {
        if (it.id === item.id) {
          return { ...it, config };
        }
        return it;
      });
      return newBlocks;
    });
    setDrawerVisible(false);
  };

  const handleConditionModalOk = () => {
    if (currentTimeBlockIndex !== null && currentBlockIndex !== null) {
      modifyBlocks((prevBlocks) => {
        const newBlocks = [...prevBlocks];
        newBlocks[currentTimeBlockIndex].blocks[currentBlockIndex].conditions =
          {
            groups,
            segment,
          };
        return newBlocks;
      });
      setConditionModalVisible(false);
    }
  };
  const moveBlockUp = (timeBlockIndex, blockIndex) => {
    if (blockIndex > 0) {
      modifyBlocks((prevBlocks) => {
        const newBlocks = [...prevBlocks];
        const [movedBlock] = newBlocks[timeBlockIndex].blocks.splice(
          blockIndex,
          1
        );
        newBlocks[timeBlockIndex].blocks.splice(blockIndex - 1, 0, movedBlock);
        return newBlocks;
      });
    }
  };

  const moveBlockDown = (timeBlockIndex, blockIndex) => {
    modifyBlocks((prevBlocks) => {
      const newBlocks = [...prevBlocks];
      if (blockIndex < newBlocks[timeBlockIndex].blocks.length - 1) {
        const [movedBlock] = newBlocks[timeBlockIndex].blocks.splice(
          blockIndex,
          1
        );
        newBlocks[timeBlockIndex].blocks.splice(blockIndex + 1, 0, movedBlock);
      }
      return newBlocks;
    });
  };

  const handleUndo = () => {
    if (history.length > 0) {
      const previousBlocks = history[history.length - 1];
      const newHistory = history.slice(0, history.length - 1);
      setFuture([blocks, ...future]);
      setHistory(newHistory);
      setBlocks(previousBlocks);
    }
  };

  const handleRedo = () => {
    if (future.length > 0) {
      const nextBlocks = future[0];
      const newFuture = future.slice(1);
      setHistory([...history, blocks]);
      setFuture(newFuture);
      setBlocks(nextBlocks);
    }
  };

  const handleCancel = () => {
    setBlocks(initialBlocks);
    setHistory([]);
    setFuture([]);
  };

  const handleSave = () => {
    setSaveLoadingButton(true);
    onSave(blocks);
    setInitialBlocks(blocks);
    setHistory([]);
    setFuture([]);
    setTimeout(() => {
      setSaveLoadingButton(false);
    }, 1000);
  };

  useEffect(() => {
    if (isOldFormat(data)) {
      setBlocks([]);
      setInitialBlocks([]);
    } else {
      setBlocks(Array.isArray(data) ? data : []);
      setInitialBlocks(Array.isArray(data) ? data : []);
    }
  }, [data]);

  // Define the floating button styles
  const floatingButtonStyle: CSSProperties = {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 1000,
  };

  return (
    <>
      {blocks.length > 0 ? (
        <>
          <Timeline
            mode="left"
            items={sortBlocks(blocks)?.map((timeBlock, timeBlockIndex) => ({
              label: (
                <a onClick={() => openTimeModal(timeBlockIndex)}>
                  {timeBlock.start.type === "immediately"
                    ? translate("pipeline_automations.immediately")
                    : `${timeBlock.start.number} ${translate(
                        `pipeline_automations.${timeBlock.start.type}`
                      )}`}
                </a>
              ),
              children: (
                <Space direction="vertical" size={"large"}>
                  {timeBlock?.blocks?.map((block, blockIndex) => (
                    <Card
                      key={blockIndex}
                      size="small"
                      title={
                        <Flex justify="space-between">
                          <Text>
                            <a
                              onClick={() =>
                                openConditionModal(timeBlockIndex, blockIndex)
                              }
                            >
                              {block.conditions &&
                              (block.conditions.groups.length > 0 ||
                                block.conditions?.segment?.id)
                                ? translate(
                                    "pipeline_automations.condition_edit"
                                  )
                                : translate(
                                    "pipeline_automations.condition_add"
                                  )}
                            </a>
                          </Text>
                          <Dropdown
                            menu={blockActionMenu(
                              timeBlockIndex,
                              blockIndex,
                              timeBlock?.blocks?.length
                            )}
                            trigger={["click"]}
                          >
                            <EllipsisOutlined />
                          </Dropdown>
                        </Flex>
                      }
                      style={{ width: 300 }}
                    >
                      <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={(event) =>
                          onDragEnd(event, timeBlockIndex, blockIndex)
                        }
                      >
                        <SortableContext
                          items={block.items.map((item) => item.id)}
                        >
                          {block.items.map((item) => (
                            <SortableItem
                              key={item.id}
                              item={item}
                              timeBlockIndex={timeBlockIndex}
                              blockIndex={blockIndex}
                              handleActionClick={handleActionClick}
                              handleDeleteItem={handleDeleteItem}
                            />
                          ))}
                        </SortableContext>
                      </DndContext>
                      <Dropdown
                        menu={actionMenu(timeBlockIndex, blockIndex)}
                        trigger={["click"]}
                      >
                        <Button
                          icon={<PlusOutlined />}
                          size="small"
                          type="primary"
                          shape="round"
                        />
                      </Dropdown>
                    </Card>
                  ))}
                  <Button
                    icon={<PlusOutlined />}
                    size="small"
                    type="dashed"
                    onClick={() => addBlock(timeBlockIndex)}
                  >
                    {translate("pipeline_automations.add_block")}
                  </Button>
                </Space>
              ),
            }))}
            pending={
              <a onClick={addTimeBlock}>
                {translate("pipeline_automations.add")}
              </a>
            }
            pendingDot={<PlusOutlined />}
            style={{ marginTop: 50 }}
          />
          <Modal
            title="Edit Conditions"
            open={isConditionModalVisible}
            onCancel={() => setConditionModalVisible(false)}
            onOk={handleConditionModalOk}
            width={800}
            destroyOnClose={true}
          >
            <FilterGroup
              handleSegments={(selectedSegments) => {
                setSegment(selectedSegments);
              }}
              handleGroups={(selectedGroup) => setGroups(selectedGroup)}
              groupsValue={groups}
              initialSegmentValue={segment}
              showInWeek
            />
          </Modal>

          {/* Modal for time editing */}
          <Modal
            title={translate("pipeline_automations.modal_title")}
            open={isModalVisible}
            onCancel={() => setModalVisible(false)}
            onOk={handleModalOk}
            destroyOnClose={true}
          >
            <Form layout="vertical">
              <Form.Item label={translate("pipeline_automations.number")}>
                <InputNumber
                  min={1}
                  value={startTime.number}
                  onChange={(value) =>
                    setStartTime({ ...startTime, number: value })
                  }
                  disabled={startTime.type === "immediately"}
                />
              </Form.Item>
              <Form.Item label={translate("pipeline_automations.type")}>
                <Select
                  value={startTime.type}
                  onChange={(value) => {
                    setStartTime({
                      ...startTime,
                      type: value,
                      number:
                        value === "immediately" ? 0 : startTime.number || 1,
                    });
                  }}
                >
                  <Select.Option value="immediately">
                    {translate("pipeline_automations.immediately")}
                  </Select.Option>
                  <Select.Option value="day">
                    {translate("pipeline_automations.day")}
                  </Select.Option>
                  <Select.Option value="week">
                    {translate("pipeline_automations.week")}
                  </Select.Option>
                  <Select.Option value="month">
                    {translate("pipeline_automations.month")}
                  </Select.Option>
                  <Select.Option value="year">
                    {translate("pipeline_automations.year")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>

          {/* Drawer for action settings */}
          <Drawer
            title={`${translate(
              "pipeline_automations.action_settings"
            )} - ${translate(`automations.${currentItem?.item.name}`)}`}
            placement="right"
            onClose={() => setDrawerVisible(false)}
            open={isDrawerVisible}
            className="customFieldsEditDrawer"
            width={"30%"}
            destroyOnClose={true}
          >
            {currentItem?.item.name === "webhook" && (
              <SendWebhookForm
                item={currentItem.item}
                onSave={handleSaveConfig}
                onCancel={() => setDrawerVisible(false)}
              />
            )}
            {currentItem?.item.name === "send_email" && (
              <SendMailForm
                item={currentItem.item}
                onSave={handleSaveConfig}
                onCancel={() => setDrawerVisible(false)}
              />
            )}
            {currentItem?.item.name === "move_pipeline" &&
              page == "pipelines" && (
                <MoveToPipelineForm
                  item={currentItem.item}
                  onSave={handleSaveConfig}
                  onCancel={() => setDrawerVisible(false)}
                />
              )}
            {/* Add other form components based on action type */}
          </Drawer>
        </>
      ) : (
        <Flex style={{ height: "80vh" }} justify="center" align="center">
          <Space direction="vertical" align="center">
            <Typography.Text>
              {translate("pipeline_automations.no_pipelines_title")}
            </Typography.Text>
            <Button icon={<PlusOutlined />} onClick={addTimeBlock}>
              {translate("pipeline_automations.add_a_time_block")}
            </Button>
          </Space>
        </Flex>
      )}
      <Space style={floatingButtonStyle}>
        <Button
          icon={<UndoOutlined />}
          onClick={handleUndo}
          disabled={history.length === 0}
        />
        <Button
          icon={<RedoOutlined />}
          onClick={handleRedo}
          disabled={future.length === 0}
        />
        <Button type="primary" onClick={handleSave} loading={saveLoadingButton}>
          Save
        </Button>
      </Space>
    </>
  );
};

export default AutomationTimeline;
