import { useForm } from "@refinedev/antd";
import {
  BaseKey,
  CanAccess,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Form, Input, Modal } from "antd";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../contexts/ContextProvider";

export default function ProductIssuesCreate() {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { id } = useParams();
  const { show } = useNavigation();
  const { formProps, saveButtonProps } = useForm({
    resource: "product_issues",
    redirect: "list",
  });

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <Modal
        open
        okButtonProps={{
          ...saveButtonProps,
        }}
        title="Create product issue"
        onClose={() => show("product_items", id as BaseKey)}
        onCancel={() => show("product_items", id as BaseKey)}
        destroyOnClose={true}
      >
        <Form
          {...formProps}
          onFinish={(values) => {
            return (
              formProps.onFinish &&
              formProps.onFinish({
                ...values,
                product: id,
                account: currentTeam?.account_id,
              })
            );
          }}
        >
          <Form.Item
            rules={[{ required: true }]}
            label={translate("product_items.product_issues.name")}
            name={"name"}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </CanAccess>
  );
}
