import { FieldTimeOutlined, WarningFilled } from "@ant-design/icons";
import {
  BaseKey,
  BaseRecord,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Badge, Space, Table, Tooltip, Typography } from "antd";
import { TableProps } from "antd/lib";

type Props = {
  data: any;
  tableProps: TableProps<BaseRecord>;
};
export default function AutomationListPage({ tableProps }: Props) {
  const translate = useTranslate();
  const { edit } = useNavigation();
  return (
    <>
      <Table
        {...tableProps}
        rowKey={(record) => record.id ?? ""}
        rowClassName={"clickableRow"}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        onRow={(record) => ({
          onClick: () => {
            edit("automations", record?.id as unknown as BaseKey);
          },
        })}
      >
        <Table.Column
          dataIndex="name"
          sorter
          title={translate("automations.name")}
          render={(value, record: any) => (
            <Space size={"small"}>
              {record.hasError && (
                <Tooltip title="Automation contains errors, please fix in order to run!">
                  <WarningFilled style={{ color: "orange" }} />
                </Tooltip>
              )}
              {value}
            </Space>
          )}
        />
        <Table.Column
          sorter
          dataIndex="status"
          title={translate("automations.status")}
          render={(value) => (
            <>
              {value ? (
                <>
                  <Badge status="success" />{" "}
                  {translate("automations.status_active")}
                </>
              ) : value == null ? (
                <>
                  <Badge status="default" />{" "}
                  {translate("automations.status_inactive")}
                </>
              ) : (
                <>
                  <Badge status="warning" />{" "}
                  {translate("automations.status_pause")}
                </>
              )}
            </>
          )}
        />
        <Table.Column
          sorter
          dataIndex="latest_run"
          title={translate("automations.latest_run")}
          render={(value) => (
            <>
              {value ? (
                <>
                  <FieldTimeOutlined />{" "}
                  {new Date(value).toLocaleString(
                    translate("pipelines.date_locale")
                  )}
                </>
              ) : (
                <Typography.Text type="secondary">
                  {translate("automations.latest_run_not")}
                </Typography.Text>
              )}
            </>
          )}
        />
      </Table>
    </>
  );
}
