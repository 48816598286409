import React, { PropsWithChildren, createContext, useState } from "react";

type PipelineContextType = {
  pipelines: any[];
  setPipelines: React.Dispatch<React.SetStateAction<any>>;
  rawPipelines: any[];
  setRawPipelines: React.Dispatch<React.SetStateAction<any>>;
  canAccess: any;
  setCanAccess: React.Dispatch<React.SetStateAction<any>>;
  selectedStage: any;
  setSelectedStage: React.Dispatch<React.SetStateAction<any>>;
};
export const PipelineContext = createContext<PipelineContextType>({
  pipelines: [],
  setPipelines: () => [],
  rawPipelines: [],
  setRawPipelines: () => [],
  canAccess: {},
  setCanAccess: () => [],
  selectedStage: null,
  setSelectedStage: () => null,
});

export const PipelineProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [pipelines, setPipelines] = useState([]);
  const [rawPipelines, setRawPipelines] = useState([]);
  const [canAccess, setCanAccess] = useState();
  const [selectedStage, setSelectedStage] = useState<any>(null);

  return (
    <PipelineContext.Provider
      value={{
        pipelines,
        setPipelines,
        rawPipelines,
        setRawPipelines,
        canAccess,
        setCanAccess,
        selectedStage,
        setSelectedStage,
      }}
    >
      {children}
    </PipelineContext.Provider>
  );
};
