import { useForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { RuleGroupType } from "react-querybuilder";
import CustomQueryBuilderIssueExport from "../../../../../components/shop/CustomQueryBuilderIssueExport";

type Props = {
  isModalVisible?: boolean;
  setIsModalVisible?: (boolean) => void;
  exportId: number;
};
export default function ProductIssuesExportEdit({
  isModalVisible,
  setIsModalVisible,
  exportId,
}: Props) {
  const [query, setQuery] = useState<RuleGroupType>({
    combinator: "and",
    rules: [],
  });
  const translate = useTranslate();
  const {
    formProps,
    saveButtonProps,
    onFinish,
    query: queryExport,
  } = useForm({
    resource: "product_issue_exports",
    action: "edit",
    id: exportId,
  });
  useEffect(() => {
    formProps.form?.setFieldValue("rule", query);
  }, [query]);
  useEffect(() => {
    formProps.form?.setFieldValue("rule", queryExport?.data?.data?.rule);
    setQuery(queryExport?.data?.data.rule);
  }, [queryExport?.data?.data?.rule]);
  return (
    <Modal
      title="Edit Export Lists"
      open={isModalVisible}
      onCancel={() => {
        setIsModalVisible?.(false);
        formProps.form?.resetFields();
      }}
      okButtonProps={{ ...saveButtonProps }}
      destroyOnClose={true}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(value) => {
          onFinish({
            ...value,
          });
          setIsModalVisible && setIsModalVisible(false);
          formProps.form?.resetFields();
        }}
      >
        <Form.Item
          name={"name"}
          label={translate("product_items.product_issue_exports.name")}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={"rule"}
          label={translate("product_items.product_issue_exports.rule")}
        >
          <CustomQueryBuilderIssueExport
            handleChange={(value) => setQuery(value)}
            initialQuery={query}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
