import {
  CommentOutlined,
  SearchOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useList, useTranslate } from "@refinedev/core";
import { AutoComplete, Empty, Input, Modal, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../contexts/ContextProvider";
import useDebounce from "../../hooks/useDebounce";
import { getContactFormatedName } from "../../utility/contactName";
import { useStyles } from "../header/styled";
import { Text } from "../text";

type Props = {
  openDialog?: boolean;
  handleCancel?: () => void;
  autocomplete?: boolean;
};

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}
interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}

export default function SearchModal({
  openDialog,
  handleCancel,
  autocomplete,
}: Props) {
  const [options, setOptions] = useState<IOptions[]>([]);
  const [value, setValue] = useState<string>("");
  const [openOptions, setOpenOptions] = useState(false);
  const debouncedValue = useDebounce(value, 500);

  const { currentTeam } = useUser();
  const { styles } = useStyles();
  const translate = useTranslate();

  const renderTitle = (title: string) => (
    <div>
      <Text size="xs" strong type="secondary" style={{ paddingLeft: 6 }}>
        {title}
      </Text>
    </div>
  );

  const renderItem = (
    title: string | undefined,
    link: string,
    id: string,
    description?: string
  ) => {
    const regex = new RegExp(
      `(${value?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")})`,
      "gi"
    );

    const parts = title != undefined ? title.split(regex) : [];
    return {
      key: id,
      value: `#${id} - ${title}`,
      label: (
        <div className={styles.labelStyle}>
          <Link
            key={id}
            to={link}
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setValue("")}
          >
            <Space direction="vertical" size={0}>
              <Space>
                {id.startsWith("contact") ? (
                  <UserOutlined />
                ) : id.startsWith("notes") ? (
                  <CommentOutlined />
                ) : (
                  <TagOutlined />
                )}
                <Text size="sm">
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={
                        part.toLowerCase() === debouncedValue.toLowerCase()
                          ? { background: "#FFFF8D" }
                          : {}
                      }
                    >
                      {part}
                    </span>
                  ))}
                </Text>
              </Space>
              {description && (
                <Text size="xs" type="secondary">
                  {description}
                </Text>
              )}
            </Space>
          </Link>
        </div>
      ),
    };
  };

  // Fetch Contacts
  const { refetch: refetchContacts } = useList<any>({
    resource: "contacts",
    meta: { select: "*" },
    filters: [
      {
        operator: "eq",
        value: currentTeam?.account_id,
        field: "account",
      },
      {
        operator: "or",
        value: [
          {
            field: "firstname",
            operator: "contains",
            value: `%${debouncedValue}%`,
          },
          {
            field: "lastname",
            operator: "contains",
            value: `%${debouncedValue}%`,
          },
          {
            field: "company_name",
            operator: "contains",
            value: `%${debouncedValue}%`,
          },
          {
            field: "email",
            operator: "contains",
            value: `%${debouncedValue}%`,
          },
        ],
      },
    ],
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const contactOptionGroup = data.data.map((item) =>
          renderItem(
            `${getContactFormatedName(
              item.company_name,
              item.firstname,
              item.lastname
            )} ${item.job_title != null ? " - " + item.job_title : ""}`,
            `/${currentTeam?.slug}/contacts/show/${item.id}`,
            `contact-${item.id}`,
            item.email
          )
        );

        if (contactOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(translate("contacts.contacts")),
              options: contactOptionGroup,
            },
          ]);
        }
      },
    },
  });

  // Fetch Deals
  const { refetch: refetchDeals } = useList<any>({
    resource: "pipeline_items",
    meta: { select: "*, pipeline(name), pipeline_stage(name)" },
    filters: [
      {
        operator: "eq",
        value: currentTeam?.account_id,
        field: "account",
      },
      { field: "name", operator: "contains", value: `%${debouncedValue}%` },
    ],
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const dealOptionGroup = data.data.map((item) =>
          renderItem(
            `${item.name}`,
            `/${currentTeam?.slug}/pipelines/show/${item.pipeline}/items/${item.id}`,
            `items-${item.id}`,
            `${item.pipeline?.name || ""} > ${item.pipeline_stage?.name || ""}`
          )
        );
        if (dealOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle("Deals"),
              options: dealOptionGroup,
            },
          ]);
        }
      },
    },
  });

  // Fetch Notes
  const { refetch: refetchNotes } = useList<any>({
    resource: "notes",
    meta: { select: "*, contacts(*)" },
    filters: [
      {
        operator: "eq",
        value: currentTeam?.account_id,
        field: "account",
      },
      { field: "text", operator: "contains", value: `%${debouncedValue}%` },
    ],
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const notesOptionGroup = data.data.map((item) =>
          renderItem(
            `${item.text}`,
            `/${currentTeam?.slug}/contacts/show/${item.contacts[0]?.id}?noteHighlight=${item.id}`,
            `notes-${item.id}`,
            item.created_at
          )
        );

        if (notesOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle(translate("notes.notes")),
              options: notesOptionGroup,
            },
          ]);
        }
      },
    },
  });

  useEffect(() => {
    if (openDialog) {
      setOptions([]);
      refetchContacts();
      refetchDeals();
      refetchNotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, openDialog]);

  if (!options) return <Spin />;

  if (autocomplete) {
    return (
      <AutoComplete
        style={{
          width: "100%",
          paddingRight: "10px",
          paddingLeft: "10px",
          height: "40px",
        }}
        open={openOptions}
        options={
          options.length
            ? options
            : [{ label: null, options: [{ label: <Empty />, value: "" }] }]
        }
        filterOption={false}
        onSearch={(value: string) => setValue(value)}
        className={styles.inputStyle}
        onFocus={() => setOpenOptions(true)}
        onBlur={() => setOpenOptions(false)}
        value={value}
        onChange={() => {
          setValue("");
        }}
      >
        <Input
          type={"filled"}
          style={{
            width: "100%",
            border: "none",
            boxShadow: "none"
          }}
          prefix={<SearchOutlined style={{ color: "#787878" }} />}
          placeholder={translate("page.home.search")}
        />
      </AutoComplete>
    );
  }
  return (
    <>
      <Modal
        title={
          <>
            <AutoComplete
              style={{
                width: "100%",
                marginRight: "1rem",
              }}
              open={false}
              options={options}
              filterOption={false}
              onSearch={(value: string) => setValue(value)}
              className={styles.inputStyle}
              value={value}
              onChange={() => {
                setValue("");
              }}
            >
              <Input
                style={{
                  height: "50px",
                  fontSize: 16,
                  borderRadius: "10px 10px 0px 0",
                  borderTop: "unset",
                }}
                className={styles.inputStyle}
                placeholder={translate("page.home.search")}
                suffix={<div className={styles.inputSuffix}>/</div>}
                prefix={<SearchOutlined className={styles.inputPrefix} />}
              />
            </AutoComplete>
          </>
        }
        centered
        open={openDialog}
        footer={null}
        closeIcon={false}
        styles={{
          body: {
            maxHeight: 400,
            height: 400,
            overflow: "auto",
            padding: "10px 0px",
            borderTop: "1px solid #eee",
          },
          header: {
            padding: 0,
            paddingBottom: 20,
            border: 0,
          },
        }}
        onCancel={handleCancel}
      >
        {options.map((item, index) => {
          return (
            <Space key={index} direction="vertical" style={{ width: "100%" }}>
              <Space style={{ padding: 5 }}>{item.label}</Space>
              {item.options.map((option, indx) => {
                return (
                  <React.Fragment key={indx}>{option.label}</React.Fragment>
                );
              })}
            </Space>
          );
        })}
      </Modal>
    </>
  );
}
