import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import { useEffect, useRef } from "react";
type Props = {
  initialValue?: any;
  jsonValue: (jsonData: any) => void;
};
export default function EditorComponent({ initialValue, jsonValue }: Props) {
  const ejInstance: any = useRef();
  const initEditor = () => {
    const editor = new EditorJS({
      holder: "editorjs",
      data: initialValue,
      autofocus: true,
      onChange: async () => {
        const content = await editor.saver.save();
        jsonValue(content);
      },
      tools: {
        header: {
          class: Header,
          config: {
            levels: [1, 2, 3],
          },
        },
      },
    });
  };

  // This will run only once
  useEffect(() => {
    if (ejInstance.current === undefined) {
      initEditor();
    }

    return () => {
      ejInstance?.current?.destroy();
      ejInstance.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ejInstance?.current]);

  return (
    <>
      <div
        id="editorjs"
        style={{
          border: "1px solid #e9e9e9",
          borderRadius: "6px",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          width: "100%",
          minHeight: "300px",
        }}
      ></div>
    </>
  );
}
