import { useNavigation, useTranslate } from "@refinedev/core";
import { Button, Empty, Space, Typography } from "antd";

export default function InformationAboutSubscriptionProduct() {
  const translate = useTranslate();
  const { replace } = useNavigation();
  return (
    <Empty
      style={{ marginTop: 40, marginBottom: 40 }}
      description={
        <Space direction="vertical" size={"middle"}>
          <Typography.Text>
            {translate("subscription_products.you_dont_have_any_subscription")}
          </Typography.Text>
          <Button
            size="small"
            type="primary"
            onClick={() => replace("subscription-products/create")}
          >
            {translate("subscription_products.buttons.add_product")}
          </Button>
        </Space>
      }
    ></Empty>
  );
}
