import { AuthProvider } from "@refinedev/core";

import i18n from "i18next";
import { jwtDecode } from "jwt-decode";
import { supabaseClient } from "../utility";

const authProvider: AuthProvider = {
  login: async ({ email, password, providerName }) => {
    // sign in with oauth

    try {
      if (providerName) {
        const { data, error } = await supabaseClient.auth.signInWithOAuth({
          provider: providerName,
        });
        if (error) {
          return {
            success: false,
            error,
          };
        }

        if (data?.url) {
          return {
            success: true,
            redirectTo: "/",
          };
        }
      }

      // sign in with email and password
      const { data, error } = await supabaseClient.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        return {
          success: false,
          error: {
            message: i18n.t(
              "pages.login.errors.invalidEmailOrPassword",
              "Invalid email or password"
            ),
            name: i18n.t("pages.login.errors.loginFailed", "Login failed"),
          },
        };
      }

      if (data?.user) {
        i18n.changeLanguage(data?.user?.user_metadata?.language ?? "en");
        return {
          success: true,
          redirectTo: "/",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: false,
      error: {
        message: "Login failed",
        name: "Invalid email or password",
      },
    };
  },
  register: async ({ email }) => {
    try {
      const { error } = await supabaseClient.functions.invoke(
        "send_email_invite",
        {
          body: {
            email,
          },
        }
      );
      if (error) throw error;

      return {
        success: true,
        successNotification: {
          message: i18n.t("pages.register.register_successfull"),
          description: i18n.t("pages.register.register_successfull_text"),
          type: "success",
        },
        redirectTo: `/verify?email=${email}`,
      };
    } catch (error: any) {
      if (error.context.status == 499) {
        return {
          success: false,
          error: {
            message: "Email already exists",
            name: "Please try with another email as this one is registred. Or try forget password!",
          },
        };
      } else
        return {
          success: false,
          error,
        };
    }
  },
  forgotPassword: async ({ email }) => {
    try {
      const { data, error } = await supabaseClient.auth.resetPasswordForEmail(
        email,
        {
          redirectTo: `${window.location.origin}/update-password`,
        }
      );

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data) {
        return {
          success: true,
          successNotification: {
            key: "forget-password-success",
            message: "Request succesfull",
            description: "The request for this email was send succesfully",
            type: "success",
          },
          redirectTo: "/",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
        successNotification: {
          key: "forget-password-error",
          message: "Request was not succesfull",
          description: "Something went wrong, please check your email address!",
          type: "error",
        },
      };
    }

    return {
      success: false,
      error: {
        message: "Forgot password failed",
        name: "Invalid email",
      },
    };
  },
  updatePassword: async ({ password, redirectTo }) => {
    try {
      const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      if (!passwordRules.test(password)) {
        return {
          success: false,
          error: {
            name: "Update Password Error",
            message: i18n.t("validation.password_requirements"),
          },
        };
      }
      const { data, error } = await supabaseClient.auth.updateUser({
        password,
      });

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data) {
        return {
          success: true,
          redirectTo: redirectTo
            ? redirectTo.path
              ? redirectTo.path
              : undefined
            : "/",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: false,
      error: {
        message: "Update password failed",
        name: "Invalid password",
      },
    };
  },
  logout: async () => {
    const { error } = await supabaseClient.auth.signOut();

    if (error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      redirectTo: "/",
    };
  },
  onError: async (error) => {
    return { error };
  },
  check: async () => {
    try {
      const { data } = await supabaseClient.auth.getSession();
      const { session } = data;

      if (!session) {
        return {
          authenticated: false,
          error: {
            message: "Check failed",
            name: "Session not found",
          },
          logout: true,
          redirectTo: "/login",
        };
      }

      // Additional check to verify session validity
      const { error } = await supabaseClient.auth.getUser(session.access_token);
      if (error) {
        return {
          authenticated: false,
          error: {
            message: "Session invalid",
            name: "Invalid session",
          },
          logout: true,
          redirectTo: "/login",
        };
      }
    } catch (error: any) {
      return {
        authenticated: false,
        error: error || {
          message: "Check failed",
          name: "Not authenticated",
        },
        logout: true,
        redirectTo: "/login",
      };
    }

    return {
      authenticated: true,
    };
  },
  getPermissions: async () => {
    const session = await supabaseClient.auth.getSession();

    if (session?.data.session) {
      return jwtDecode<any>(session.data.session?.access_token);
    }

    return null;
  },
  getIdentity: async () => {
    const { data } = await supabaseClient.auth.getUser();

    if (data?.user) {
      return {
        ...data.user,
        name: data.user.email,
      };
    }

    return null;
  },
};

export default authProvider;
