import { useSelect } from "@refinedev/antd";
import { useUser } from "../contexts/ContextProvider";

export const useStageSelect = (pipelineId?: number | string) => {
  const { currentTeam } = useUser();

  return useSelect({
    resource: "pipeline_stages",
    optionLabel: "name",
    optionValue: "id",
    filters: pipelineId
      ? [
          { field: "pipeline", operator: "eq", value: pipelineId },
          {
            field: "account",
            value:
              currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
            operator: "eq",
          },
        ]
      : [
          {
            field: "account",
            value:
              currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
            operator: "eq",
          },
        ],
    meta: {
      select: "*",
    },
    sorters: [{ field: "order", order: "asc" }],
  });
};
