import React from "react";
import {Typography, Button, Space, Divider, Flex, Card} from "antd";
import PopoverConfirmation from "./PopoverConfirmation";
import InfoComponent from "../../InfoComponent";
import { useSettingsLogic } from "../hooks/useSettingsLogic";

const { Title, Paragraph } = Typography;

interface SettingsContainerProps {
  modulePath: string;
  title: string;
  description: string;
  intro?: string;
  children: React.ReactNode;
}

const SettingsContainer: React.FC<SettingsContainerProps> = (
  {
    modulePath,
    title,
    description,
    intro,
    children,
  }) => {
    const {
      settings,
      popoverVisible,
      setPopoverVisible,
      updateModuleStatus,
      translate,
  } = useSettingsLogic({ modulePath });

  return (
    <>
      <Title level={3}>{translate(title)}</Title>
      {/* Flex Layout */}
      <Flex justify="space-between" align="middle" gap={64}>
        <Space direction="vertical">
          <Paragraph>{translate(description)}</Paragraph>
        </Space>

        {!settings[`${modulePath}.enabled`] && (
          <Button
            type="primary"
            onClick={() => updateModuleStatus(`${modulePath}.enabled`, true)}
          >
            {translate("settings.activate")} {translate(title)}
          </Button>
        )}
      </Flex>

      {settings[`${modulePath}.enabled`] ? (
        <>
          <Divider />
          {intro && (
            <Card style={{ marginBottom: "3rem" }}>
              <Paragraph>{translate(intro)}</Paragraph>
            </Card>
          )}
          <Space direction="vertical" size="large">
            {children}
          </Space>
          <Divider />
          <Flex justify="end" style={{ marginTop: "24px" }}>
            <PopoverConfirmation
              title={translate("settings.deactivate") + " " + translate(title)}
              content={translate("settings.deactivate_content")}
              confirmLabel={translate("settings.deactivate")}
              onConfirm={() => updateModuleStatus(`${modulePath}.enabled`, false)}
              visible={popoverVisible}
              onVisibilityChange={setPopoverVisible}
            />
          </Flex>
        </>
      ) : (
        <>
          <InfoComponent />
        </>
      )}
    </>
  );
};

export default SettingsContainer;