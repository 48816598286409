import { useSelect } from "@refinedev/antd";
import { useUser } from "../contexts/ContextProvider";

export const usePipelineSelect = () => {
  const { currentTeam } = useUser();

  return useSelect({
    resource: "pipelines",
    optionLabel: "name",
    optionValue: "id",
    meta: {
      select: "id, name",
    },
    filters: [
      {
        field: "account",
        value:
          currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
        operator: "eq",
      },
    ],
  });
};
