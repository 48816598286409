import React from "react";
import { useParams } from "react-router-dom";
import ExceptionShowDrawer from "../../components/exceptions/ExceptionShowDrawer";
interface ExceptionShowProps {
  children?: React.ReactNode; // Add children prop
}
const ExceptionShow: React.FC<ExceptionShowProps> = () => {
  const params = useParams<{ id: string }>(); // Type params to include `id`

  if (!params.id) {
    return null;
  }

  return (
    <ExceptionShowDrawer
      exceptionId={params.id}
      visible={false}
      onClose={function (): void {
        throw new Error("Function not implemented.");
      }}
    />
  );
};

export default ExceptionShow;
