export enum BillingType {
  OneTime = "one_time",
  Start = "start",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
  //Forever = "forever",
}
export type IProduct = {
  type: "issue" | "item" | "service";
  name: string;
  description: string;
  reference_id: number;
  price_per_item: number;
  variant?: any;
  tax_rate: number;
};
export interface IItemProduct extends IProduct {
  type: "issue";
  issues: number;
}
export type IPhase<T = BillingType> = {
  key?: number;
  name?: string;
  title?: string;
  price?: number;
  tax_rate?: number;
  type?: "flat_fee";
  pre_contract?: boolean;
  auto_convert_to_contract?: boolean;
  automatic_renewal?: null | {
    interval: T;
    interval_count: number;
  };
  issues?: {
    interval: T;
    interval_count: number;
  };
  start?:
    | string
    | {
        interval: T;
        interval_count: number;
      };
  end?:
    | string
    | {
        interval: T;
        interval_count: number;
      };
  products: IItemProduct[] | any;
  billing_interval: {
    interval: BillingType;
    interval_count: number;
  };
  start_date: string;
  end_date: string | null;
};
