// SendWebhook.tsx
import { gray } from "@ant-design/colors";
import { useTranslate } from "@refinedev/core";
import { Button, Form, Input, Space } from "antd";
import React from "react";
import { Text } from "../../text";

export const SendWebhookDisplay: React.FC<{ item: any }> = ({ item }) => {
  return (
    <p>
      {item.config?.url ? (
        <>
          <Text size={"xs"} style={{ color: gray[4] }}>
            URL
          </Text>
          <br /> <Text strong>{item.config?.url}</Text>
        </>
      ) : (
        <Text type="warning">No URL configured.</Text>
      )}
    </p>
  );
};

export const SendWebhookForm: React.FC<{
  item: any;
  onSave: (config: any) => void;
  onCancel: () => void;
}> = ({ item, onSave, onCancel }) => {
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      onSave(values);
    } catch (error) {
      const values = form.getFieldsValue();
      onSave(values);
    }
  };

  const translate = useTranslate();
  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        initialValues={item.config}
      >
        <Form.Item
          label="URL"
          name="url"
          rules={[{ required: true, message: "Please input the URL!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
      <div>
        <Space>
          <Button onClick={onCancel}>{translate("buttons.cancel")}</Button>
          <Button type="primary" onClick={handleSubmit}>
            {translate("buttons.save")}
          </Button>
        </Space>
      </div>
    </div>
  );
};
