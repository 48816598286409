import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import useFile from "../../hooks/useFile";
import useTeam from "../../hooks/useTeam";
import { useNavigate, useParams } from "react-router-dom";

type UserContextType = {
  avatarUrl?: string;
  avatarColor?: string;
  setAvatarUrl?: Dispatch<SetStateAction<string | undefined>>;
  setAvatarColor?: Dispatch<SetStateAction<string | undefined>>;
} & ReturnType<typeof useTeam>;

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);

export const UserProvider: React.FC<
  PropsWithChildren<{ outsideTeam?: boolean }>
> = ({ children, outsideTeam }) => {
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [avatarColor, setAvatarColor] = useState<string>();
  const team = useTeam();
  const { getFileDataURL } = useFile();
  useEffect(() => {
    const getAvatar = async () => {
      const res: any = await new Promise((resolve) => {
        const fetchedImg = getFileDataURL(
          team?.currentTeam?.metadata?.avatar?.image
        );
        resolve(fetchedImg);
      });
      setAvatarUrl(res);
    };

    if (team?.currentTeam?.metadata?.avatar?.image) {
      getAvatar();
    }
  }, [getFileDataURL, team?.currentTeam?.metadata?.avatar?.image]);

  const navigate = useNavigate();
  const { teamSlug } = useParams();
  const validSlugs = team?.allTeams?.map((team: any) => team.slug);

  if (
    !outsideTeam &&
    validSlugs !== undefined &&
    !validSlugs?.includes(teamSlug ?? "")
  ) {
    navigate("/");
  }
  return (
    <UserContext.Provider
      value={{
        ...team,
        avatarUrl: avatarUrl,
        setAvatarUrl: setAvatarUrl,
        avatarColor,
        setAvatarColor,
      }}
    >
      {outsideTeam ? children : team.currentTeam && children}
    </UserContext.Provider>
  );
};
