import { useNavigation } from "@refinedev/core";
import { Breadcrumb, Flex, Space, Typography } from "antd";

type CustomBreadcrumbProps = {
  items: Array<{
    title: string;
    url?: string;
    icon?: React.ReactNode;
  }>;
  extra?: string | React.ReactNode; // Optionales Prop
};

const CustomBreadcrumb: React.FC<CustomBreadcrumbProps> = ({ items, extra }) => {
  const { push } = useNavigation();

  const handleClick = (url, e) => {
    e.preventDefault();
    push(url);
  };

  // Map the items to the format expected by Breadcrumb's new `items` prop
  const breadcrumbItems = items.map((item) => {
    const content = (
      <Flex gap={12}>
        {item.icon && item.icon} {item.title}
      </Flex>
    );

    return {
      key: item.url || item.title,
      href: item.url,
      title: item.url ? (
        <span
          onClick={(e) => handleClick(item.url, e)}
          className="clickableRow"
        >
        {content}
      </span>
      ) : (
        content
      ),
    };
  });

  return (
    <div style={{marginTop: "0px", marginBottom: "24px"}}>
      <Breadcrumb items={breadcrumbItems} />
      {extra && (
        <Typography.Text style={{fontWeight: "normal" }} type="secondary">{extra}</Typography.Text>
        )
      }
    </div>
  )
};

export default CustomBreadcrumb;
