import { useList, useTranslate } from "@refinedev/core";
import {Button, Divider, Flex, Form, Select} from "antd";
import debounce from "lodash/debounce";
import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import AddressModal from "../contacts/modals/AddressModal";

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}

type Props = {
  contactId: string;
  initialValue?: any;
  onSelect?: (value: string) => void;
  getAddress?: (address: any) => void;
  filterIds?: string[];
  label?: string;
  name?: string;
  initialOption?: { value: string | number; label: React.ReactNode };
  required?: boolean;
};

export default function AddressAutoselect({
  contactId,
  initialValue,
  onSelect,
  getAddress,
  filterIds,
  label,
  name,
  initialOption,
  required = true,
}: Props) {
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptionGroup[]>([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { currentTeam } = useUser();
  const translate = useTranslate();
  const inputRef = useRef<any>(null);

  const formatAddressLabel = (address: any) => {
    let label = "";

    if (address.type === "PO_Box_Address") {
      label += `${address.street ?? ""}\n`;
    } else if (address.type === "Packing_Station") {
      if (address.address_supplement_1) {
        label += `${translate("contacts.post_number")}: ${
          address.address_supplement_1
        }\n`;
      }
      label += `${translate("contacts.packstation")}: ${
        address.street ?? ""
      }\n`;
    } else {
      if (address.address_supplement_1) {
        label += `${address.address_supplement_1}\n`;
      }
      label += `${address.street ?? ""}\n`;
    }

    label += `${address.zip ?? ""} ${address.city ?? ""}\n`;
    label += `${address.country ?? ""}\n`;

    if (address.label) {
      label += `[${address.label}]\n`;
    }

    return label.trim();
  };

  const renderItem = (address: any) => {
    return {
      value: String(address.id),
      label: formatAddressLabel(address),
      item: address,
    };
  };

  const { refetch: refetchAddresses } = useList<any>({
    resource: "addresses",
    filters: [
      {
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      },
      {
        field: "contacts.id",
        operator: "eq",
        value: contactId,
      },
      {
        operator: "or",
        value: [
          {
            field: "street",
            operator: "contains",
            value: `%${value}%`,
          },
          {
            field: "city",
            operator: "contains",
            value: `%${value}%`,
          },
          {
            field: "zip",
            operator: "contains",
            value: `%${value}%`,
          },
        ],
      },
    ],
    meta: { select: "*, contacts!inner(*)" },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const addressOptions = data.data.map(renderItem);
        if (filterIds && addressOptions.length > 0 && filterIds.length > 0) {
          const stringFilterIds = filterIds.map(String);
          const filteredOptions = addressOptions.filter(
            (obj) => !stringFilterIds.includes(obj.value)
          );
          setOptions(filteredOptions);
        } else {
          setOptions(addressOptions);
        }
      },
    },
  });

  useEffect(() => {
    refetchAddresses();
  }, [value, currentTeam?.account_id, contactId]);

  return (
    <>
      {currentTeam?.account_id && (
        <Form.Item
          name={name ?? "address"}
          rules={[{ required }]}
          label={label ?? translate("contacts.address")}
        >
          <Select
            style={{ width: "100%", minWidth: "150px", marginRight: "1rem" }}
            showSearch
            allowClear
            onClear={() => onSelect && onSelect("")}
            value={
              typeof initialValue !== "object"
                ? String(initialValue ?? "")
                : undefined
            }
            options={[
              ...options,
              ...(initialOption &&
              !options.some(
                (item) => item.value === String(initialOption.value)
              )
                ? [initialOption]
                : []),
            ]}
            filterOption={false}
            onSearch={debounce((value: string) => setValue(value), 300)}
            onSelect={(value: string, option: any) => {
              if (getAddress) {
                getAddress(option.item);
              }
              if (onSelect) {
                onSelect(value);
              }
            }}
            dropdownRender={(menu) => (
              <Flex vertical>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div>
                  <Button
                    block
                    type="default"
                    size="small"
                    ref={inputRef}
                    onClick={() => setOpenCreateModal(true)}
                  >
                    {translate("contacts.add_new_address")}
                  </Button>
                </div>
              </Flex>
            )}
          />
        </Form.Item>
      )}
      {openCreateModal && (
        <AddressModal
          visible={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
          onSuccess={(address) => {
            const formattedAddress = {
              value: String(address.id),
              label: formatAddressLabel(address),
              item: address,
            };

            setOptions((prev) => [...prev, formattedAddress]);

            if (getAddress) {
              getAddress(address);
            }
            if (onSelect) {
              onSelect(String(address.id));
            }

            setTimeout(() => {
              inputRef.current?.focus();
            }, 500);

            setOpenCreateModal(false);
          }}
          contactId={contactId}
        />
      )}
    </>
  );
}
