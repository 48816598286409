import { useNavigation } from "@refinedev/core";
import CreateOrEditInvoice from "../../../components/invoices/CreateOrEditInvoice";

export default function InvoiceCreate() {
  const { list } = useNavigation();

  return (
    <CreateOrEditInvoice
      open={true}
      onClose={() => list("invoices")}
      invoice={null}
    />
  );
}
