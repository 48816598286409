import React from "react";
import {Typography, Switch, Row, Col, Space} from "antd";

const { Text } = Typography;

interface ToggleSwitchProps {
  title: string;
  description?: string;
  checked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const SettingsItem: React.FC<ToggleSwitchProps> = (props) => {
  const { title, description, checked, onChange, disabled, children } = props;
  const isCheckedPropSet = Object.prototype.hasOwnProperty.call(props, 'checked'); // Check if checked prop is set

  return (

    <>
      <Row style={{marginBottom: "12px", marginTop: "12px"}}>
        <Col span={8}>
          <Text strong>{title}</Text>
        </Col>
        <Col span={16}>
          <Space direction={"vertical"} size={"small"}>

            {/* Render switch if checked prop is set */}
            {isCheckedPropSet !== undefined &&
                <>
                    <Switch disabled={disabled} checked={checked} onChange={onChange}/>
                  {description && <Text type="secondary">{description}</Text>}
                </>
            }

            {/* Render children */}
            {children}

          </Space>
        </Col>
      </Row>
    </>
  );
}

export default SettingsItem;