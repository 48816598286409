import React from "react";
import { Popover, Typography, Button } from "antd";
import { WarningOutlined } from "@ant-design/icons";

interface PopoverConfirmationProps {
  title: string;
  content: string;
  confirmLabel: string;
  onConfirm: () => void;
  visible: boolean;
  onVisibilityChange: (visible: boolean) => void;
}

const PopoverConfirmation: React.FC<PopoverConfirmationProps> = ({
                                                                   title,
                                                                   content,
                                                                   confirmLabel,
                                                                   onConfirm,
                                                                   visible,
                                                                   onVisibilityChange,
                                                                 }) => (
  <Popover
    content={
      <div>
        <Typography.Text>{content}</Typography.Text>
        <div style={{ marginTop: 16 }}>
          <Button type="primary" danger onClick={onConfirm}>
            {confirmLabel}
          </Button>
        </div>
      </div>
    }
    title={
      <>
        <WarningOutlined style={{ marginRight: 8 }} />
        {title}
      </>
    }
    trigger="click"
    open={visible}
    onOpenChange={onVisibilityChange}
  >
    <Button type="link" danger>
      {title}
    </Button>
  </Popover>
);

export default PopoverConfirmation;