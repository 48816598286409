import { useForm } from "@refinedev/antd";
import {
  CanAccess,
  useNavigation,
  useNotification,
  useTranslate,
} from "@refinedev/core";
import { DatePicker, Form, Modal, Select } from "antd";
import dayjs from "dayjs";
import React, { PropsWithChildren, useState } from "react";
import AddressAutoselect from "../../../components/common/AddressAutoselect";
import ContactAutoSelect from "../../../components/contacts/ContactAutoSelect";
import InvoiceTable from "../../../components/shop/product-items";
import { useUser } from "../../../contexts/ContextProvider";
import { useSubscriptionProductSelect } from "../../../hooks/useSubscriptionProductSelect";
import { supabaseClient } from "../../../utility";

const OrdersCreate: React.FC<PropsWithChildren> = ({ children }) => {
  const translate = useTranslate();
  const [selectedContact, setSelectedContact] = useState<string>("");
  const [productItems, setProductItems] = useState();
  const [deliveryContact, setDeliveryContact] = useState<string | undefined>(
    undefined
  );
  const [type, setType] = React.useState("subscription");

  const { currentTeam } = useUser();
  const { list } = useNavigation();
  const { open } = useNotification();

  const { query: productResult, selectProps: phaseSelectProp } =
    useSubscriptionProductSelect();

  const { formProps, saveButtonProps } = useForm({
    resource: "orders",
    action: "create",
    redirect: false,
    invalidates: ["resourceAll"],
  });
  const handleFinish = async ({ startDate, ...values }: any) => {
    const payload: any = {
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      subscriptionProductId: values.subscription_product,
      invoiceContactId: parseInt(selectedContact),
      invoiceContactAddressId: values.invoice_contact_address,
      type: values.type,
      product_items: productItems,
    };

    if (deliveryContact?.trim()) {
      payload.deliveryContactId = deliveryContact;
      if (values.delivery_contact_address) {
        payload.deliveryContactAddressId = values.delivery_contact_address;
      }
    }

    try {
      const { data, error } = await supabaseClient.functions.invoke(
        "shop-core/order/create",
        {
          body: {
            ...payload,
          },
          method: "POST",
        }
      );

      if (error) {
        console.log("error", data);
        open?.({
          description: translate("alerts.toast.error"),
          message: error?.error,
          type: "error",
        });
        console.error("Error creating subscription:", error);
      } else {
        open?.({
          description: translate("notifications.success"),
          message: translate("notifications.createSuccess"),
          type: "success",
        });
        list("orders", "replace");
      }
    } catch (err) {
      console.error("Unexpected error:", err);
    }
  };

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>
        <Modal
          open
          centered
          maskClosable={false}
          title={translate("orders.create.title")}
          onCancel={() => {
            list("orders", "replace");
          }}
          okText={translate("buttons.save")}
          okButtonProps={{
            ...saveButtonProps,
          }}
          width={1000}
        >
          <Form {...formProps} layout="vertical" onFinish={handleFinish}>
            <Form.Item
              name="type"
              label={translate("orders.fields.type")}
              rules={[{ required: true, message: "Please select the type" }]}
              initialValue={type}
            >
              <Select onChange={(value) => setType(value)}>
                <Select.Option value="simple">
                  {translate("orders.type.simple")}
                </Select.Option>
                <Select.Option value="subscription">
                  {translate("orders.type.subscription")}
                </Select.Option>
              </Select>
            </Form.Item>
            <ContactAutoSelect
              onSelect={(value) => {
                setSelectedContact(value);
              }}
              name="invoice_contact"
              initialValue={selectedContact}
              label={translate("subscriptions.fields.user")}
            />
            {selectedContact && (
              <AddressAutoselect
                name={"invoice_contact_address"}
                contactId={selectedContact}
                label={translate(
                  "subscriptions.fields.invoice_contact_address"
                )}
              />
            )}
            <ContactAutoSelect
              onSelect={(value) => {
                setDeliveryContact(value);
              }}
              name="delivery_contact"
              initialValue={deliveryContact}
              label={translate("subscriptions.fields.delivery_contact")}
              required={false}
            />
            {deliveryContact && (
              <AddressAutoselect
                name={"delivery_contact_address"}
                contactId={deliveryContact}
                label={translate(
                  "subscriptions.fields.delivery_contact_address"
                )}
              />
            )}
            {type === "subscription" && (
              <Form.Item
                name={"subscription_product"}
                label={translate("subscriptions.fields.subscription_product")}
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: "100%" }}
                  {...phaseSelectProp}
                  options={productResult.data?.data.map((item) => ({
                    value: item.id,
                    label: `${item?.product_item?.title} - ${item.name}`,
                  }))}
                />
              </Form.Item>
            )}
            {type === "simple" && (
              <InvoiceTable
                onProductsChange={(value) => setProductItems(value)}
              />
            )}
            {type === "subscription" && (
              <Form.Item
                label={translate("subscriptions.fields.start_date")}
                name="startDate"
                initialValue={dayjs()}
              >
                <DatePicker
                  minDate={dayjs()}
                  format={translate("global.date_format")}
                />
              </Form.Item>
            )}
          </Form>
        </Modal>
        {children}
      </>
    </CanAccess>
  );
};

export default OrdersCreate;
