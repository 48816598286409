import { InfoCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useModalForm } from "@refinedev/antd";
import {
  CanAccess,
  useCreateMany,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
  UploadFile,
  theme,
} from "antd";
import { UploadProps } from "antd/lib";
import React, { useState } from "react";
import CategoryAutoselect from "../../../components/shop/CategoryAutoselect";
import { useUser } from "../../../contexts/ContextProvider";
import { usePipelineContext } from "../../../contexts/PipelineProvider";
import { supabaseClient } from "../../../utility";
type Props = {
  disableRedirect?: boolean;
  onSuccess?: (data: any) => void;
  changeView?: () => void;
};
export default function CreateProductItem({
  disableRedirect,
  changeView,
  onSuccess,
}: Props) {
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [period, setPeriod] = React.useState();
  const [current, setCurrent] = useState(0);

  const { canAccess } = usePipelineContext();

  const [productCategories, setProductCategories] = React.useState<number[]>(
    []
  );
  const [type, setType] = React.useState("item"); // New state for type
  const primaryColor = theme?.useToken()?.token?.colorPrimary;
  const translate = useTranslate();
  const { currentTeam } = useUser();

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter((file) => file.status !== "error"));
  const onRemove = async (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };
  const dummyRequest = async (options: any) => {
    const { onSuccess } = options;

    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const props: UploadProps = {
    onChange: handleChange,
    multiple: true,
    customRequest: dummyRequest,
    onRemove: onRemove,
    listType: "picture-card",
    maxCount: 5,
  };
  const { show, list } = useNavigation();

  const { mutateAsync } = useUpdate();
  const { mutateAsync: createMany } = useCreateMany({});
  const { modalProps, formProps, close, open } = useModalForm<any>({
    resource: "product_items",
    action: "create",
    redirect: false,
    defaultVisible: true,
    onMutationSuccess: async (data) => {
      try {
        if (!data?.data?.id) {
          console.error("no id provided");
        } else {
          let fileArr: any[] = [];
          if (fileList) {
            fileArr = await Promise.all(
              fileList.map(async (file: any) => {
                const fileName = `${Date.now()}-${file.name}`;
                const { data: fileData, error } = await supabaseClient.storage
                  .from("files")
                  .upload(
                    `teams/${currentTeam?.account_id}/shop/product_items/${data?.data?.id}/${fileName}`,
                    file.originFileObj,
                    {
                      //test
                    }
                  );
                if (error) {
                  throw error;
                } else {
                  return {
                    ...fileData,
                    name: fileName,
                  };
                }
              })
            );
          }
          setFileList([]);
          if (productCategories) {
            await createMany({
              resource: "product_item_has_product_category",
              values: productCategories.map((cat) => ({
                product_category: cat,
                product_item: data.data.id,
                account: currentTeam?.account_id,
              })),
              invalidates: ["all"],
              successNotification: false,
            });
          }
          await mutateAsync({
            resource: "product_items",
            id: data.data.id,
            values: { images: fileArr },
            successNotification: false,
            invalidates: ["all"],
          });

          !disableRedirect ? show("product_items", data.data.id) : close?.();
          if (onSuccess) {
            onSuccess(data?.data);
          }
        }
      } catch (error: any) {
        //
        console.log("error", error.message);
      }
    },
    warnWhenUnsavedChanges: !disableRedirect ? true : false,
    successNotification: false,
    invalidates: ["all"],
  });

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleFinish = async (values: any) => {
    try {
      const { subscription_type, ...valuesWithoutSubscriptionType } = values;

      if (values.product_categories) delete values.product_categories;
      const data = await formProps?.onFinish?.({
        ...valuesWithoutSubscriptionType,
        type:
          values.type == "item"
            ? "item"
            : subscription_type
            ? "issue"
            : "subscription",
        images: [],
        account: currentTeam?.account_id,
      });
    } catch (error) {
      //
    }
  };
  const steps = [
    {
      title: "Type Selection",
      content: (
        <>
          <Typography.Text style={{ fontWeight: "500" }}>
            Single Item
          </Typography.Text>
          <Typography.Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            aliquet odio ac ante cursus, nec mollis metus porttitor. Morbi leo
            erat, luctus id tempor vel, aliquam vel turpis.
          </Typography.Paragraph>
          <Typography.Text style={{ fontWeight: "500" }}>
            Subscription
          </Typography.Text>
          <Typography.Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            aliquet odio ac ante cursus, nec mollis metus porttitor. Morbi leo
            erat, luctus id tempor vel, aliquam vel turpis.
          </Typography.Paragraph>
        </>
      ),
    },
    {
      title: "Details",
      content: <></>,
    },
  ];

  const next = async () => {
    try {
      // Validate only the fields in the current step
      const fieldsToValidate = current === 0 ? ["type"] : ["title"];
      await formProps?.form?.validateFields(fieldsToValidate);
      setCurrent((prev) => prev + 1);
    } catch (err) {
      console.error("Validation failed:", err);
    }
  };
  const prev = () => setCurrent((prev) => prev - 1);
  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <Modal
        {...modalProps}
        open={open}
        centered
        title={translate("product_items.create.add_product_item")}
        width={760}
        onCancel={() => {
          if (!disableRedirect) {
            list("product_items", "replace");
          } else {
            close();
            changeView?.();
          }

          console.log(modalProps.open);
        }}
        okText={translate("product_items.buttons.add_product")}
      >
        <Form {...formProps} onFinish={handleFinish} layout="vertical">
          <Form.Item
            name="type"
            label={translate("product_items.fields.type")}
            extra={
              <div style={{ padding: "10px 100px 10px 10px" }}>
                {translate(`product_items.fields.type_info.${type}`)}
              </div>
            }
            rules={[
              { required: true, message: "Please select a product type" },
            ]}
            initialValue={type}
          >
            <Select
              onChange={(value) => setType(value)}
              disabled={current > 0} // Prevent changing type after Step 1
            >
              <Select.Option value="item">
                {translate("product_items.type.item")}
              </Select.Option>
              <Select.Option value="subscription">
                {translate("product_items.type.subscription")}
              </Select.Option>
            </Select>
          </Form.Item>
          {type == "subscription" && (
            <div style={{ marginBottom: 20 }}>
              <Form.Item
                name={"subscription_type"}
                label={translate("product_items.fields.subscription_type")}
              >
                <Switch />
              </Form.Item>
              <Typography.Text style={{}}>
                <InfoCircleOutlined /> For subscription type products price,
                duration etc. can be setup in a later step
              </Typography.Text>
            </div>
          )}
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter the title" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea rows={4} />
          </Form.Item>
          {canAccess?.personal_modules?.shop?.ecommerce && (
            <CategoryAutoselect
              multiple
              name="product_categories"
              onSelect={(items) => setProductCategories(items)}
            />
          )}
          <Form.Item
            name="images"
            label="Upload Images"
            valuePropName="fileList"
            getValueFromEvent={(e) => e?.fileList}
          >
            <Upload
              listType="picture-card"
              multiple
              fileList={fileList}
              onChange={({ fileList }) => setFileList(fileList)}
            >
              {fileList.length < 5 && <PlusCircleFilled />}
            </Upload>
          </Form.Item>

          {type == "item" ? (
            <Row gutter={20}>
              <Col span={6}>
                <Form.Item
                  name={"netto_price"}
                  initialValue={0}
                  label={translate("product_items.fields.amount")}
                >
                  <InputNumber
                    precision={2}
                    addonAfter={"EUR"}
                    decimalSeparator=","
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={"sales_tax"}
                  initialValue={19}
                  label={translate("product_items.fields.sales_tax")}
                >
                  <Select popupMatchSelectWidth={false}>
                    <Select.Option value={0}>0%</Select.Option>
                    <Select.Option value={7}>7%</Select.Option>
                    <Select.Option value={19}>19%</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Form>
      </Modal>
    </CanAccess>
  );
}
